import axios from 'axios';
import store from '../store/store';

export default async function getFormats(files: Array<string>): Promise<void> {
  return axios({
    method: 'OPTIONS',
    url: `${process.env.VUE_APP_BASE_URL}/api/operations/convert`,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { union: true },
    data: {
      files,
    },
  })
    .then(async (response) => {
      if (response.status !== 200) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return getFormats(store.getters.uploadedFilesIds);
      }
      store.commit("setAvailableFormats", response.data.format);
    })
    .catch((err) => {
      console.error(err);
    });
}
