import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 1,
  class: "tooltip"
}
const _hoisted_3 = {
  id: "preview-tooltip",
  class: "tooltip-text"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "viewer-backdrop" }
const _hoisted_6 = {
  key: 2,
  class: "page-viewer"
}
const _hoisted_7 = { class: "viewer-header" }
const _hoisted_8 = { class: "file-details" }
const _hoisted_9 = {
  class: "viewer-content",
  ref: "viewerContent"
}

import { ref } from "vue";
import { mapGetters } from "@/store/utils/mapGetters";
import { analytics } from '@/utils/analitycs';
import CloseIcon from "@/components/icons/CloseIcon.vue";
import EyeIcon from "@/components/icons/EyeIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FileViewer',
  props: {
    index: {},
    fileName: {},
    useIconButton: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const {
  selectedFormat,
  operationIsExecuting,
  currentLocation
} = mapGetters();
const show = ref(false);
const supportedOnlyOfficeFormats = [
  "CSV",
  "DOC",
  "DOCX",
  "DOT",
  "DOTM",
  "DOTX",
  "DOCM",
  "HTM",
  "HTML",
  "MHTML",
  "MHT",
  "NUMBERS",
  "ODP",
  "ODS",
  "ODT",
  "OPC",
  "PDF",
  "POTM",
  "POTX",
  "PPSM",
  "PPSX",
  "PPT",
  "PPTM",
  "PPTX",
  "TSV",
  "TXLF",
  "TXT",
  "XLS",
  "XLSM",
  "XLSX",
  "XML",
  "XPS"
];

function isFormatSupported(format: string): boolean {
  return currentLocation.value === '/watermark' || supportedOnlyOfficeFormats.includes(format);
}

function trackFilePreview(): void {
  if (currentLocation.value === "/convert") {
    analytics.convertTracker.trackPreview();
  } else if (currentLocation.value === "/watermark") {
    analytics.watermarkTracker.trackPreview();
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.useIconButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          id: "preview-file-icon",
          disabled: _unref(operationIsExecuting)
        }, [
          _createVNode(EyeIcon, {
            class: "icon-color",
            width: "1.5rem",
            height: "1.5rem",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (show.value = true, _unref(analytics).historyTracker.trackPreview()))
          })
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.useIconButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("span", _hoisted_3, " This format is not available to preview ", 512), [
            [_vShow, !isFormatSupported(_unref(selectedFormat))]
          ]),
          _createElementVNode("button", {
            class: "outline-button",
            disabled: !isFormatSupported(_unref(selectedFormat)),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (show.value = true, trackFilePreview()))
          }, " Preview file ", 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
      [_vShow, show.value]
    ]),
    (show.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("section", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Preview file", -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.fileName), 1)
            ]),
            _createElementVNode("button", {
              class: "close-viewer-button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (show.value = false))
            }, [
              _createVNode(CloseIcon, {
                class: "icon-color",
                width: "1.5rem",
                height: "1.5rem"
              })
            ])
          ]),
          _createElementVNode("section", _hoisted_9, [
            _renderSlot(_ctx.$slots, "default")
          ], 512)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})