import axios from 'axios';
import WatermarkSettings from '@/interfaces/watermarkSettings';

export default function applyWatermarks(filesIds: Array<string>, settings: WatermarkSettings) {
  return axios({
    method: 'POST',
    url: `${process.env.VUE_APP_BASE_URL}/api/operations/watermark`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      files: filesIds,
      text: settings.text,
      color: settings.color,
      fontFamily: settings.fontFamily,
      fontSize: settings.fontSize,
      angle: settings.angle,
      distance: settings.distance
    },
  })
    .then((response) => response.data)
    .catch((err) => console.error(err));
}