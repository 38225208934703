import store from "@/store/store";
import File from '@/interfaces/File';
import FAILED from "@/constants/failed";

export default function getCountFileSuccessRate() {
  const totalFiles = store.getters.uploadedFiles.length;
  let filesFailed = 0;
  store.getters.uploadedFiles.forEach((file: File) => {
    if (file.nrWords === FAILED || file.nrPages === FAILED) {
      filesFailed++;
    }
  })
  return `${totalFiles - filesFailed} of ${totalFiles}`;
}