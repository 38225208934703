import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { DocumentEditor } from "@onlyoffice/document-editor-vue";
import { mapGetters } from "@/store/utils/mapGetters";
import getDownloadId from "@/services/getDownloadId";
import signJwt from './utils/signJwt';
import mapFileTypeToEditor from './utils/mapFileTypeToEditor';
import { config } from './utils/config'
import FileFromHistory from "@/interfaces/FileFromHistory";


export default /*@__PURE__*/_defineComponent({
  __name: 'OnlyOffice',
  props: {
    fileFromHistory: {},
    index: {}
  },
  setup(__props: any) {

const props = __props;
const store = useStore();
const newConf = ref(null);
const showEditor = ref(false);
const {
  uploadedFiles,
  selectedFormat,
  currentLocation
} = mapGetters();
const documentServerUrl = process.env.VUE_APP_ONLYOFFICE_URL;
const oidcUser = computed(() => store.getters.oidcUser);

function generateKey() {
  return Array.from({ length: 20 }, () => (Math.random() * 16 | 0).toString(16)).join('');
}

async function setConfigValue() {
  const { id, fileFormat } = setFileSource();
  newConf.value = { ...config };
  await isFileWatermarked(id, fileFormat);
  newConf.value.document.key = generateKey();
  newConf.value.editorConfig.user.name = oidcUser.value.name;
  newConf.value.token = await signJwt(process.env.VUE_APP_ONLYOFFICE_SECRET, newConf.value);
  showEditor.value = true;
}

function setFileSource() {
  return props.fileFromHistory
    ? { id: props.fileFromHistory.id, fileFormat: props.fileFromHistory.fileFormat.toUpperCase() }
    : { id: uploadedFiles.value[props.index].id, fileFormat: selectedFormat.value };
}

async function isFileWatermarked(id: string, fileFormat: string) {
  if (currentLocation.value === "/watermark" && !props.fileFromHistory) {
    const downloadId = await getDownloadId(id, 'watermarked');
    newConf.value.document.url = `${process.env.VUE_APP_BASE_URL}/api/files/${id}/${downloadId}?format=PDF`;
    newConf.value.document.fileType = "pdf"
  } else {
    const downloadId = await getDownloadId(id);
    newConf.value.document.url = `${process.env.VUE_APP_BASE_URL}/api/files/${id}/${downloadId}?format=${fileFormat}`;
    newConf.value.document.fileType = mapFileTypeToEditor(fileFormat);
  }
}

onMounted(setConfigValue);

return (_ctx: any,_cache: any) => {
  return (showEditor.value)
    ? (_openBlock(), _createBlock(_unref(DocumentEditor), {
        key: 0,
        id: "only-office",
        documentServerUrl: _unref(documentServerUrl),
        config: newConf.value
      }, null, 8, ["documentServerUrl", "config"]))
    : _createCommentVNode("", true)
}
}

})