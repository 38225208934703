import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteIcon',
  props: ['width', 'height'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createElementBlock("svg", {
      width: __props.width,
      height: __props.height,
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("rect", {
        width: "24",
        height: "24",
        fill: "none"
      }, null, -1),
      _createElementVNode("path", {
        d: "M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z",
        fill: "currentColor"
      }, null, -1),
      _createElementVNode("rect", {
        width: "24",
        height: "24"
      }, null, -1)
    ]), 8, _hoisted_1))
  ]))
}
}

})