import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "operations-result-container"
}
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 1,
  class: "small-spinner"
}

import DownloadIconFull from "@/components/icons/DownloadIconFull.vue";
import RetryIcon from "@/components/icons/RetryIcon.vue";
import { mapGetters } from "@/store/utils/mapGetters";
import { analytics } from '@/utils/analitycs';
import downloadAll from "@/services/downloadAll";


export default /*@__PURE__*/_defineComponent({
  __name: 'OptionsAfterConvert',
  emits: ["reset"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const {
  areAllFilesConverted,
  showDownloadAllButton,
  filesAreDownloading
} = mapGetters();

return (_ctx: any,_cache: any) => {
  return (_unref(areAllFilesConverted))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(showDownloadAllButton))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              id: "download-all-button",
              class: "custom-blue-button",
              disabled: _unref(filesAreDownloading),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(downloadAll) && _unref(downloadAll)(...args)))
            }, [
              (!_unref(filesAreDownloading))
                ? (_openBlock(), _createBlock(DownloadIconFull, {
                    key: 0,
                    width: "0.75rem",
                    height: "0.75rem"
                  }))
                : (_openBlock(), _createElementBlock("span", _hoisted_3)),
              _cache[2] || (_cache[2] = _createTextVNode(" Download all "))
            ], 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          id: "start-over-button-convert",
          class: "custom-blue-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('reset'), _unref(analytics).convertTracker.trackStartOver()))
        }, [
          _createVNode(RetryIcon, {
            width: "0.75rem",
            height: "0.75rem"
          }),
          _cache[3] || (_cache[3] = _createTextVNode(" Start over "))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})