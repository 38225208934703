import axios from 'axios';
import FAILED from "@/constants/failed";

export default async function checkOperation(operationId: string) {
  return axios({
    method: 'GET',
    url: `${process.env.VUE_APP_BASE_URL}/api/operations/${operationId}`,
  })
    .then((response) => {
      if (response.data.status === FAILED) {
        console.error(response.data);
      }
      return response.data;
    })
    .catch((err) => err);
}
