import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "count-results-container" }
const _hoisted_2 = { class: "operation-container" }
const _hoisted_3 = {
  id: "nr-pages-box",
  class: "operation-container"
}
const _hoisted_4 = { class: "uploading-state" }
const _hoisted_5 = {
  id: "nr-words-box",
  class: "operation-container"
}
const _hoisted_6 = { class: "uploading-state" }

import { mapGetters } from "@/store/utils/mapGetters";
import FAILED from "@/constants/failed";


export default /*@__PURE__*/_defineComponent({
  __name: 'CountResults',
  props: {
    index: {}
  },
  setup(__props: any) {

const props = __props;
const {
  filePagesCounted,
  fileWordsCounted,
  uploadedFiles,
} = mapGetters();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("span", _hoisted_4, "Pages: ", 512), [
          [_vShow, _unref(filePagesCounted)[_ctx.index]]
        ]),
        _createElementVNode("p", {
          id: "nr-of-pages",
          class: _normalizeClass(["count-results", { failed: _unref(uploadedFiles)[_ctx.index].nrPages === _unref(FAILED) }])
        }, _toDisplayString(_unref(uploadedFiles)[_ctx.index].nrPages), 3)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("span", _hoisted_6, "Words: ", 512), [
          [_vShow, _unref(fileWordsCounted)[_ctx.index]]
        ]),
        _createElementVNode("p", {
          id: "nr-of-words",
          class: _normalizeClass(["count-results", { failed: _unref(uploadedFiles)[_ctx.index].nrWords === _unref(FAILED) }])
        }, _toDisplayString(_unref(uploadedFiles)[_ctx.index].nrWords), 3)
      ])
    ], 512), [
      [_vShow, _unref(filePagesCounted)[_ctx.index] && _unref(fileWordsCounted)[_ctx.index]]
    ])
  ]))
}
}

})