import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]


export default /*@__PURE__*/_defineComponent({
  __name: 'ReuseIcon',
  props: ['width', 'height'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createElementBlock("svg", {
      width: __props.width,
      height: __props.height,
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("rect", {
        width: "24",
        height: "24",
        fill: "none"
      }, null, -1),
      _createElementVNode("path", {
        d: "M11.8182 7.63636V9.81818L14.7273 6.90909L11.8182 4V6.18182C10.2751 6.18182 8.79523 6.7948 7.70411 7.88592C6.61299 8.97705 6 10.4569 6 12C6 13.1418 6.33455 14.2036 6.90182 15.0982L7.96364 14.0364C7.63636 13.4327 7.45455 12.7273 7.45455 12C7.45455 10.8427 7.91428 9.73278 8.73263 8.91444C9.55097 8.0961 10.6609 7.63636 11.8182 7.63636ZM16.7345 8.90182L15.6727 9.96364C15.9927 10.5745 16.1818 11.2727 16.1818 12C16.1818 13.1573 15.7221 14.2672 14.9037 15.0856C14.0854 15.9039 12.9755 16.3636 11.8182 16.3636V14.1818L8.90909 17.0909L11.8182 20V17.8182C13.3613 17.8182 14.8411 17.2052 15.9323 16.1141C17.0234 15.023 17.6364 13.5431 17.6364 12C17.6364 10.8582 17.3018 9.79636 16.7345 8.90182Z",
        fill: "currentColor"
      }, null, -1),
      _createElementVNode("rect", {
        width: "24",
        height: "24"
      }, null, -1)
    ]), 8, _hoisted_1))
  ]))
}
}

})