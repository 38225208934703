import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "welcome-message-container"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

import { mapGetters } from "@/store/utils/mapGetters";


export default /*@__PURE__*/_defineComponent({
  __name: 'WelcomeMessage',
  setup(__props) {

const { currentLocation, areFilesUploaded } = mapGetters();

return (_ctx: any,_cache: any) => {
  return (!_unref(areFilesUploaded))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Welcome!", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("p", null, "A2A UI is a web interface that leverages the A2A conversion API.", -1)),
        (_unref(currentLocation) === '/convert')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
              _createElementVNode("h5", null, "Convert files", -1),
              _createElementVNode("p", null, " Use the form above to select the files you want to process. The files can be dragged and dropped or selected from your local storage. Select the language for your document(s) and click Next. After the file was uploaded, select the format you want to convert to and click the Convert button. ", -1)
            ])))
          : (_unref(currentLocation) === '/count')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
                _createElementVNode("h5", null, "Count pages and words", -1),
                _createElementVNode("p", null, " Use the form above to select the files you want to process. The files can be dragged and dropped or selected from your local storage. Select the language for your document(s) and click Count. After the operation finishes, you will get the number of pages and words for each uploaded file. ", -1)
              ])))
            : (_unref(currentLocation) === '/watermark')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
                  _createElementVNode("h5", null, "Watermarking:", -1),
                  _createElementVNode("p", null, " Use the form above to select the files you want to watermark. The files can be conveniently dragged and dropped or selected from your local storage. Customize your watermark settings, such as text, position, opacity, and font size. Click Apply to embed the watermark onto your selected files. ", -1)
                ])))
              : _createCommentVNode("", true),
        _cache[5] || (_cache[5] = _createElementVNode("h5", null, "History", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("p", null, " The files you have previously used in A2A are populated in this section. This is where you can: ", -1)),
        _cache[7] || (_cache[7] = _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createElementVNode("span", null, "Download files in available formats.")
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("span", null, "Re-use files if you need to perform a different conversion or get the word/page count.")
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("span", null, "Delete the file if you want to remove it from the A2A servers earlier than the 30 days period.")
          ])
        ], -1))
      ]))
    : _createCommentVNode("", true)
}
}

})