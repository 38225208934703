import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "language-selector" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { id: "language-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "langauge-pill" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "info" }

import {
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  nextTick,
} from "vue";
import ArrowIcon from "@/components/icons/DropdownArrowIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import { useStore } from "vuex";
import { mapGetters } from "@/store/utils/mapGetters";
import Language from "@/interfaces/Language";
import ISO6391 from "iso-639-1";


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageSelector',
  props: {
    index: {},
    applyForFiles: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();
const searchQuery = ref<string>('');
const languages = ref<Array<Language>>([]);
const languageSelector = ref<HTMLElement | null>(null);
const searchInput = ref<HTMLElement | null>(null);
const languageOptions =  ref<HTMLElement | null>(null);
const removeLanguageButtons =  ref<Array<HTMLElement | null>>([]);
const appContainer = ref<HTMLElement | null>(null);
const isDropdownCloseToBottom = ref(false);
const fileLimitReached = ref(false);
const {
  isLanguageDropdownVisible,
  languageList,
  uploadedFiles
} = mapGetters();

const filteredLanguage = computed(() => {
  if (!searchQuery.value) return languages.value;

  return languages.value.filter((language) =>
    language.fullName
      .toLocaleLowerCase()
      .startsWith(searchQuery.value.toLocaleLowerCase())
  );
});

const showDropdown = computed(() => isLanguageDropdownVisible.value[props.index] || !selectedLanguages.value.length);
const showLanguages = computed(() => !isLanguageDropdownVisible.value[props.index] && selectedLanguages.value.length);
const placeholder = computed(() => !selectedLanguages.value.length ? "Add file languages" : "");
const selectedLanguages = computed(() => uploadedFiles.value[props.index].language);

function selectLanguage(language: Language, index: number) {
  if (selectedLanguages.value.length < 3) {
    store.commit("setResetGlobalSelector", true);
    store.commit("addFileLanguage", {
      payload: language.fullName,
      index,
    });
    store.commit("addFileLanguageCode", {
      payload: language.shortName,
      index,
    });
  }
}

function removeLanguage(languageIndex: number) {
  fileLimitReached.value = false;
  store.commit("setResetGlobalSelector", true);
  store.commit("removeFileLanguage", {
    fileIndex: props.index,
    languageIndex,
  });
  store.commit("removeFileLanguageCode", {
    fileIndex: props.index,
    languageIndex,
  });
}

function scrollToView() {
  languageSelector.value?.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "nearest",
  });
}

async function toggleDropdown(event: MouseEvent, index: number) {
  if (removeLanguageButtons.value.some(button => button && button.contains(event.target as HTMLElement | null))) {
    return;
  } else if (selectedLanguages.value.length === 3 && !isLanguageDropdownVisible.value[index]) {
    fileLimitReached.value = true;
    return;
  }

  store.commit("toggleLanguageDropdown", index);
  setIsDropdownCloseToBottom();
  await nextTick();
  if (isLanguageDropdownVisible.value[index]) {
    searchInput.value?.focus();
    scrollToView();
  } else {
    searchInput.value?.blur();
  }
  searchQuery.value = "";
}

function closeOnOutsideClick(event: MouseEvent) {
  const clickedOutside = !languageSelector.value?.contains(event.target as HTMLElement | null);

  if (isLanguageDropdownVisible.value[props.index] && clickedOutside) {
    searchQuery.value = "";
    fileLimitReached.value = false;
    store.commit("setIsLanguageDropdownVisible", { payload: false, index: props.index });
  }
  if(fileLimitReached.value && clickedOutside) fileLimitReached.value = false;
}

async function setIsDropdownCloseToBottom() {
  await nextTick();
  const proximityThreshold = (2 / 100) * window.innerHeight;
  const rect = languageOptions.value?.getBoundingClientRect() ?? { bottom: window.innerHeight };
  const distanceFromBottom = window.innerHeight - rect.bottom;
  if (distanceFromBottom < proximityThreshold && props.index > 1) {
    isDropdownCloseToBottom.value = true;
  } else {
    isScrollbar();
  }
}

function isScrollbar() {
  const isScrollbar = (appContainer.value?.scrollHeight || 0) > (appContainer.value?.clientHeight || 0);

  if(isScrollbar && props.index > 1) {
    isDropdownCloseToBottom.value = true;
  }
}

onMounted(() => {
  languageList.value.forEach((lang: string, index: number) => {
    const language = ISO6391.getName(languageList.value[index]);
    if (language) {
      languages.value.push({
        fullName: ISO6391.getName(languageList.value[index]),
        shortName: languageList.value[index],
      });
    }
  });
  languages.value.sort((a, b) => a.fullName.localeCompare(b.fullName));
  appContainer.value = document.getElementById('app');
  document.addEventListener('click', closeOnOutsideClick);
});

onBeforeUnmount(() => document.removeEventListener('click', closeOnOutsideClick));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "languageSelector",
    ref: languageSelector
  }, [
    _withDirectives(_createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", {
        class: "language-dropdown-popover",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleDropdown($event, _ctx.index)))
      }, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
          class: "language-dropdown",
          ref_key: "searchInput",
          ref: searchInput,
          type: "text",
          placeholder: placeholder.value
        }, null, 8, _hoisted_2), [
          [_vModelText, searchQuery.value]
        ]),
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["language-options", { reverse: isDropdownCloseToBottom.value }]),
          ref_key: "languageOptions",
          ref: languageOptions
        }, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredLanguage.value, (language, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                onClick: ($event: any) => (selectLanguage(language, props.index))
              }, _toDisplayString(language.fullName), 9, _hoisted_4))
            }), 128))
          ])
        ], 2), [
          [_vShow, _unref(isLanguageDropdownVisible)[props.index]]
        ])
      ])
    ], 512), [
      [_vShow, showDropdown.value]
    ]),
    _withDirectives(_createElementVNode("section", {
      class: _normalizeClass(["selected-languages", {error:  fileLimitReached.value }]),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (toggleDropdown($event, _ctx.index)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedLanguages.value, (language, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("span", _hoisted_5, [
            _createTextVNode(_toDisplayString(language) + " ", 1),
            _createElementVNode("button", {
              class: "remove-language-button",
              ref_for: true,
              ref_key: "removeLanguageButtons",
              ref: removeLanguageButtons,
              onClick: ($event: any) => (removeLanguage(index))
            }, [
              _createVNode(CloseIcon, {
                class: "icon-color",
                width: "1rem",
                height: "1rem"
              }),
              _cache[4] || (_cache[4] = _createElementVNode("i", { class: "gl-icon gl-icon-cancel" }, null, -1))
            ], 8, _hoisted_6)
          ])
        ]))
      }), 128)),
      _withDirectives(_createElementVNode("span", _hoisted_7, " You can only add up to 3 languages in one document ", 512), [
        [_vShow, fileLimitReached.value]
      ])
    ], 2), [
      [_vShow, showLanguages.value]
    ]),
    _createVNode(ArrowIcon, {
      class: _normalizeClass(["arrow icon-color", { 'arrow-rotate': _unref(isLanguageDropdownVisible)[props.index] }]),
      width: "1.625rem",
      height: "1.625rem",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (toggleDropdown($event, _ctx.index)))
    }, null, 8, ["class"])
  ], 512))
}
}

})