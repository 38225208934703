import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

import { computed } from "vue";
import { mapGetters } from "@/store/utils/mapGetters";
import setProcessButtonText from "@/services/setProcessButtonText";
import File from "@/interfaces/File";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProcessButton',
  emits: ["process-files"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const {
  areFilesUploaded,
  operationIsExecuting,
  uploadedFiles,
  uploadedFilesIds,
} = mapGetters();

const filesAreStillUploading = computed(
  () => uploadedFiles.value.length <= uploadedFilesIds.value.length
);

const areAllLanguagesSelected = computed(
  () => uploadedFiles.value.every((file: File) => file.language.length > 0)
);

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("button", {
    id: "process-button",
    class: "blue-button",
    disabled: 
      filesAreStillUploading.value || !areAllLanguagesSelected.value || _unref(operationIsExecuting)
    ,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('process-files')))
  }, _toDisplayString(_unref(setProcessButtonText)()), 9, _hoisted_1)), [
    [_vShow, _unref(areFilesUploaded)]
  ])
}
}

})