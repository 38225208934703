enum MatomoConvert {
  UPLOAD_LINK = 'Click here link',
  OCR_TOGGLE = 'OCR option',
  REMOVE_FILE = 'Click Remove file',
  CONVERT = 'Click Convert',
  DOWNLOAD = 'Click Download',
  PREVIEW = 'Click Preview file',
  START_OVER = 'Click Start over',
}

export default MatomoConvert;
