import axios from "axios";

export default function getDownloadId(fileId: string, watermarked: string | null) {
  const url = watermarked !== undefined ?
  `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}/${watermarked}` :
  `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}`;

  return axios({
    method: "POST",
    url,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.data)
    .catch((err) => console.error(err));
}
