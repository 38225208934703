import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "switch-buttons-container" }

import { onMounted } from "vue";
import { useStore } from "vuex";
import { mapGetters } from "@/store/utils/mapGetters";


export default /*@__PURE__*/_defineComponent({
  __name: 'ThePageSwitch',
  setup(__props) {

const store = useStore();
const { operationIsExecuting, currentLocation } = mapGetters();

onMounted(() => {
  store.commit("setCurrentLocation", window.location.pathname);
  store.commit("resetHistoryModal");
  store.commit("resetFileValues");
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["buttons-row", { disabled: _unref(operationIsExecuting) }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: "/",
        id: "convert-switch",
        class: _normalizeClass(["switch-button", { 'active': _unref(currentLocation) === '/convert' }])
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Convert ")
        ])),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_router_link, {
        to: "/count",
        id: "count-switch",
        class: _normalizeClass(["switch-button", { 'active': _unref(currentLocation) === '/count' }])
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Count ")
        ])),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_router_link, {
        to: "/watermark",
        id: "watermark-switch",
        class: _normalizeClass(["switch-button", { 'active': _unref(currentLocation) === '/watermark' }])
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Watermark ")
        ])),
        _: 1
      }, 8, ["class"])
    ])
  ], 2))
}
}

})