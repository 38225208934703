import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]


export default /*@__PURE__*/_defineComponent({
  __name: 'PencilIcon',
  props: ['width', 'height'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.width,
    height: __props.height,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M0.5 12.5501V15.0835C0.5 15.3168 0.683333 15.5001 0.916667 15.5001H3.45C3.55833 15.5001 3.66667 15.4585 3.74167 15.3751L12.8417 6.28346L9.71667 3.15846L0.625 12.2501C0.541667 12.3335 0.5 12.4335 0.5 12.5501ZM15.2583 3.8668C15.5833 3.5418 15.5833 3.0168 15.2583 2.6918L13.3083 0.741797C12.9833 0.416797 12.4583 0.416797 12.1333 0.741797L10.6083 2.2668L13.7333 5.3918L15.2583 3.8668Z",
      fill: "#FFF"
    }, null, -1)
  ]), 8, _hoisted_1))
}
}

})