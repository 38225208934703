import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "total-count-results-container"
}
const _hoisted_2 = { class: "total-pages" }
const _hoisted_3 = { class: "small-spinner" }
const _hoisted_4 = { class: "total-words" }
const _hoisted_5 = { class: "small-spinner" }

import { mapGetters } from "@/store/utils/mapGetters";
import calculateTotalCountResults from "./utils/calculateTotalCountResults";
import getCountFileSuccessRate from "./utils/getCountFileSuccessRate";


export default /*@__PURE__*/_defineComponent({
  __name: 'TotalCountResults',
  setup(__props) {

const {
  areAllFilesCounted,
  operationIsExecuting,
} = mapGetters();

return (_ctx: any,_cache: any) => {
  return (_unref(areAllFilesCounted))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", null, "Total: ( " + _toDisplayString(_unref(getCountFileSuccessRate)()) + " )", 1),
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createTextVNode(" Pages: ")),
          _withDirectives(_createElementVNode("span", null, _toDisplayString(_unref(calculateTotalCountResults)("pages")), 513), [
            [_vShow, !_unref(operationIsExecuting)]
          ]),
          _withDirectives(_createElementVNode("span", _hoisted_3, null, 512), [
            [_vShow, _unref(operationIsExecuting)]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createTextVNode(" Words: ")),
          _withDirectives(_createElementVNode("span", null, _toDisplayString(_unref(calculateTotalCountResults)("words")), 513), [
            [_vShow, !_unref(operationIsExecuting)]
          ]),
          _withDirectives(_createElementVNode("span", _hoisted_5, null, 512), [
            [_vShow, _unref(operationIsExecuting)]
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})