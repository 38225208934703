import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]


export default /*@__PURE__*/_defineComponent({
  __name: 'RetryIcon',
  props: ['width', 'height'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.width,
    height: __props.height,
    viewBox: "0 0 14 14",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M11.7081 2.29085C10.3497 0.932513 8.42473 0.14918 6.30807 0.365846C3.24973 0.67418 0.733066 3.15751 0.3914 6.21585C-0.0669337 10.2575 3.05807 13.6658 6.99973 13.6658C9.65807 13.6658 11.9414 12.1075 13.0081 9.86585C13.2747 9.30751 12.8747 8.66585 12.2581 8.66585C11.9497 8.66585 11.6581 8.83251 11.5247 9.10751C10.5831 11.1325 8.32473 12.4158 5.85807 11.8658C4.00807 11.4575 2.5164 9.94918 2.12473 8.09918C1.42473 4.86585 3.88307 1.99918 6.99973 1.99918C8.38307 1.99918 9.6164 2.57418 10.5164 3.48251L9.25807 4.74085C8.73307 5.26585 9.09973 6.16585 9.8414 6.16585H12.8331C13.2914 6.16585 13.6664 5.79085 13.6664 5.33251V2.34085C13.6664 1.59918 12.7664 1.22418 12.2414 1.74918L11.7081 2.29085Z",
      fill: "#FFF"
    }, null, -1)
  ]), 8, _hoisted_1))
}
}

})