import MatomoCategory from '@/enums/matomo/MatomoCategory';
import MatomoConvert from '@/enums/matomo/MatomoConvert';
import { AnalyticsService } from '@/services/analyticsService';

export class ConvertTracker {
  private analytics: AnalyticsService;

  constructor(analytics: AnalyticsService) {
    this.analytics = analytics;
  }

  private track(action: string, event?: string): void {
    this.analytics.trackEvent({
      category: MatomoCategory.CONVERT,
      action,
      event,
    });
  }

  public trackConvert() {
    this.track(MatomoConvert.CONVERT);
  }

  public trackDownload() {
    this.track(MatomoConvert.DOWNLOAD);
  }

  public trackOcrToggle(value: string) {
    this.track(MatomoConvert.OCR_TOGGLE, value);
  }

  public trackPreview() {
    this.track(MatomoConvert.PREVIEW);
  }

  public trackRemoveFile() {
    this.track(MatomoConvert.REMOVE_FILE);
  }

  public trackStartOver() {
    this.track(MatomoConvert.START_OVER);
  }

  public trackUploadLink() {
    this.track(MatomoConvert.UPLOAD_LINK);
  }
}
