import axios from 'axios';
import store from '../store/store';
import FAILED from "@/constants/failed";

const countApi = {
  async countPages(fileId: string) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_BASE_URL}/api/operations/count-pages`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        files: [fileId],
      },
    })
      .then((response) => {
        const operationId = Object.values(response.data);
        return operationId[0];
      })
      .catch((err: string) => console.error(err));
  },

  async getCountPagesResult(id: string, index: number) {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}/page-count`,
    })
      .then((response) => {
        store.commit("setUploadedFiles", {
          payload: response.data.pages,
          prop: "nrPages",
          index: index,
        });
      })
      .catch(() => FAILED);
  },
  async returnCountPagesResult(id: string) {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}/page-count`,
    })
      .then((response) => response.data.pages)
      .catch(() => 'N/A');
  },
  // executes word count and returns the operations id-s
  async countWords(fileId: string) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_BASE_URL}/api/operations/count-words`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        files: [fileId],
      },
    })
      .then((response) => {
        const operationId = Object.values(response.data);
        return operationId[0];
      })
      .catch((err: string) => console.error(err));
  },
  async getCountWordsResult(id: string, index: number) {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}/word-count`,
    })
      .then((response) => {
        store.commit("setUploadedFiles", {
          payload: response.data.words,
          prop: "nrWords",
          index: index,
        });
      })
      .catch(() => FAILED);
  },
  async returnCountWordsResult(id: string) {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}/word-count`,
    })
      .then((response) => response.data.words)
      .catch(() => 'N/A');
  },
  // checks if page count operation was already executed for the respective file
  async checkPageCount(id: string) {
    return axios({
      method: 'HEAD',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}/page-count`,
    })
      .then((response) => response.status)
      .catch((error) => error.response.status);
  },
  // checks if word count operation was already executed for the respective file
  async checkWordCount(id: string) {
    return axios({
      method: 'HEAD',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}/word-count`,
    })
      .then((response) => response.status)
      .catch((error) => error.response.status);
  },
};
export default countApi;
