export const oidcSettings = {
  authority: process.env.VUE_APP_AUTHORITY,
  clientId: process.env.VUE_APP_CLIENT_ID,
  redirectUri: `${process.env.VUE_APP_HOST_NAME}/auth`,
  silent_redirect_uri: `${process.env.VUE_APP_HOST_NAME}/silent-auth`,
  post_logout_redirect_uri: `${process.env.VUE_APP_HOST_NAME}/logout`,
  responseType: 'id_token token',
  scope: 'openid email A2A profile GLHomeHeader'
} as const;

