import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "operations-result-container"
}
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 1,
  class: "small-spinner"
}

import { useStore } from "vuex"
import { mapGetters } from "@/store/utils/mapGetters";
import { analytics } from '@/utils/analitycs';
import downloadWatermarkZipFiles from "@/services/watermarks/downloadWatermarkZipFiles";
import PencilIcon from "@/components/icons/PencilIcon.vue";
import RetryIcon from "@/components/icons/RetryIcon.vue";
import DownloadIconFull from "@/components/icons/DownloadIconFull.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'OptionsAfterWatermark',
  emits: ["reset"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const store = useStore();
const {
  isWatermarkOperationStarted,
  operationIsExecuting,
  filesAreDownloading,
  uploadedFiles
} = mapGetters();

function goBackToEdit() {
  store.commit("setIsWatermarkOperationStarted", false);
  analytics.watermarkTracker.trackEditAll();
}

return (_ctx: any,_cache: any) => {
  return (_unref(isWatermarkOperationStarted) && !_unref(operationIsExecuting))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          id: "edit-watermark",
          class: "custom-blue-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (goBackToEdit()))
        }, [
          _createVNode(PencilIcon, {
            width: "0.75rem",
            height: "0.75rem"
          }),
          _cache[3] || (_cache[3] = _createTextVNode(" Edit all "))
        ]),
        (_unref(uploadedFiles).length > 1)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              id: "download-all-button",
              class: "custom-blue-button",
              disabled: _unref(filesAreDownloading),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(downloadWatermarkZipFiles), _unref(analytics).watermarkTracker.trackDownload()))
            }, [
              (!_unref(filesAreDownloading))
                ? (_openBlock(), _createBlock(DownloadIconFull, {
                    key: 0,
                    width: "0.75rem",
                    height: "0.75rem"
                  }))
                : (_openBlock(), _createElementBlock("span", _hoisted_3)),
              _cache[4] || (_cache[4] = _createTextVNode(" Download all "))
            ], 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          id: "start-over-button-watermark",
          class: "custom-blue-button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('reset'), _unref(analytics).watermarkTracker.trackStartOver()))
        }, [
          _createVNode(RetryIcon, {
            width: "0.75rem",
            height: "0.75rem"
          }),
          _cache[5] || (_cache[5] = _createTextVNode(" Start over "))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})