export default function formatFileSize(bytes: number): string {
  const fileSizeInKilobytes: number = Math.ceil(bytes / 1024);
  let fileSize: string;

  if (fileSizeInKilobytes > 1000) {
    const fileSizeInMegabytes: number = Math.round((fileSizeInKilobytes / 1000) * 100) / 100;
    if (fileSizeInMegabytes > 1000) {
      const fileSizeInGigabytes: number = Math.round((fileSizeInMegabytes / 1000) * 100) / 100;
      fileSize = `${fileSizeInGigabytes} GB`;
    } else {
      fileSize = `${fileSizeInMegabytes} MB`;
    }
  } else {
    fileSize = `${fileSizeInKilobytes} KB`;
  }
  return fileSize;
}
