import axios from "axios";

export default function getApiVersion() {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BASE_URL}/api/version`,
  })
    .then((response) => response.data)
    .catch((err) => console.error(err));
}
