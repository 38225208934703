import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]


export default /*@__PURE__*/_defineComponent({
  __name: 'ClearHistoryIcon',
  props: ['width', 'height'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createElementBlock("svg", {
      width: __props.width,
      height: __props.height,
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("rect", {
        width: "24",
        height: "24",
        fill: "none"
      }, null, -1),
      _createElementVNode("path", {
        d: "M13 12H14.35V14.538L16.546 15.807L15.871 16.977L13 15.321V12ZM20.2 14.7C20.2 18.183 17.383 21 13.9 21C12.181 21 10.624 20.316 9.49 19.2H6.7C5.71 19.2 4.9 18.39 4.9 17.4V6.6H15.7V8.661C18.301 9.435 20.2 11.847 20.2 14.7ZM7.6 14.7C7.6 11.217 10.417 8.4 13.9 8.4H6.7V17.4H8.203C7.816 16.581 7.6 15.663 7.6 14.7ZM13.9 10.2C11.416 10.2 9.4 12.216 9.4 14.7C9.4 17.184 11.416 19.2 13.9 19.2C16.384 19.2 18.4 17.184 18.4 14.7C18.4 12.216 16.384 10.2 13.9 10.2ZM13.45 3.9H16.6V5.7H4V3.9H7.15L8.05 3H12.55L13.45 3.9Z",
        fill: "currentColor"
      }, null, -1),
      _createElementVNode("rect", {
        width: "24",
        height: "24"
      }, null, -1)
    ]), 8, _hoisted_1))
  ]))
}
}

})