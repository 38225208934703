import { Buffer } from 'buffer';

function base64ToUint8Array(base64Contents: string): Uint8Array {
  const base64Contents2 = base64Contents.replace(/-/g, '+').replace(/_/g, '/').replace(/\s/g, '');
  const content = Buffer.from(base64Contents2, 'base64').toString('utf-8');
  return new Uint8Array(content.split('').map((c) => c.charCodeAt(0)));
}

function stringToUint8Array(contents: string): Uint8Array {
  const encoded = Buffer.from(contents, 'utf-8').toString('base64');
  return base64ToUint8Array(encoded);
}

function uint8ArrayToString(unsignedArray: Uint8Array): string {
  const base64string = Buffer.from(unsignedArray).toString('base64');
  return base64string.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

const stringToArrayBuffer = (string: string) => {
  const byteArray = new Uint8Array(string.length);
  for (let i = 0; i < string.length; i += 1) {
    byteArray[i] = string.codePointAt(i) as number;
  }
  return byteArray;
};

export default async function signJwt(
  privateKey: string,
  payload: object,
): Promise<string> {
  const header = {
    alg: 'HS256',
    typ: 'JWT',
  };

  const nowInSeconds = Math.floor(Date.now() / 1000);
  const neverEndingExpInSeconds = nowInSeconds + 300;

  const payloadInternal = {
    ...payload,
    iat: nowInSeconds,
    exp: neverEndingExpInSeconds,
  };

  const stringifiedHeader = JSON.stringify(header);
  const stringifiedPayload = JSON.stringify(payloadInternal);

  const headerBase64 = uint8ArrayToString(stringToUint8Array(stringifiedHeader));
  const payloadBase64 = uint8ArrayToString(stringToUint8Array(stringifiedPayload));
  const headerAndPayload = `${headerBase64}.${payloadBase64}`;

  const messageAsUint8Array = stringToUint8Array(headerAndPayload);

  const rawKey = stringToArrayBuffer(privateKey);
  const key = await crypto.subtle.importKey('raw', rawKey, { name: 'HMAC', hash: 'SHA-256' }, true, ['sign']);

  const signature = await crypto.subtle.sign(
    'HMAC',
    key,
    messageAsUint8Array,
  );

  const base64Signature = uint8ArrayToString(new Uint8Array(signature));

  return `${headerAndPayload}.${base64Signature}`;
}