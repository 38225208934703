const defaultText =  "sample text";
const defaultColor = '#C1BEBE';
const defaultScale = 1;
const defaultDensity = 30;
const defaultAngle = -45;

export function drawWatermarkPreview(
  canvas: HTMLCanvasElement,
  text = defaultText,
  color = defaultColor,
  scale = defaultScale,
  density = defaultDensity,
  angle = defaultAngle,
  lengthMultiplier = 2,
) {
  const context = canvas?.getContext?.('2d') as CanvasRenderingContext2D;
  if (!context) {return}

  context.resetTransform();
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.font = '16px Arial';
  context.fillStyle = color;
  context.textAlign = 'center';
  context.translate(canvas.width / 2, canvas.height / 2);
  context.scale(scale, scale);
  context.rotate((angle * Math.PI) / 180);
  const textWidth = context.measureText(text).width;
  const textLength = (1 / scale) * 3;
  for (
    let height = -canvas.height * textLength;
    height < canvas.height * textLength;
    height += density * 1.2
    ) {
    context.fillText(text, 0, height);
    for (let i = 1; i < textLength * lengthMultiplier; i++) {
      context.fillText(text, -i * (textWidth + density), height);
      context.fillText(text, i * (textWidth + density), height);
    }
  }
}

export default function drawWatermarkOnElement(
  layer: HTMLCanvasElement,
  element: HTMLElement,
  text = defaultText,
  color = defaultColor,
  scale = defaultScale,
  density = defaultDensity,
  angle = defaultAngle
) {
  layer.width = element.offsetWidth;
  layer.height = element.offsetHeight;
  const lengthMultiplier = Math.max(Math.round(layer.height / (layer.width * 2)), 2);
  drawWatermarkPreview(layer, text, color, scale, density, angle, lengthMultiplier);
}