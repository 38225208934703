import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "history-header" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { id: "history-file-box" }
const _hoisted_4 = { class: "hidden-file-details" }
const _hoisted_5 = { class: "actions-row" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

import {
  reactive,
  computed,
  onMounted,
  onUpdated,
  ref,
} from "vue";
import ClearHistoryIcon from "@/components/icons/ClearHistoryIcon.vue";
import { useStore } from "vuex";
import { mapGetters } from "@/store/utils/mapGetters";
import historyApi from "@/services/historyApi";
import countApi from "@/services/countApi";
import deleteFileApi from "@/services/deleteFile";
import { deleteFileWarningPopup, deleteAllFilesWarningPopup } from "@/services/popups";
import { analytics } from '@/utils/analitycs';
import FileFromHistory from "@/interfaces/FileFromHistory";
import HistoryFile from "./HistoryFile.vue";
import FileActions from "./FileActions.vue";
import FileCountResults from "./FileCountResults.vue";
import FileLanguage from "./FileLanguage.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserHistory',
  emits: ["remove-file-called-from-history", "remove-all-files-called-from-history", "re-use"],
  setup(__props, { expose: __expose, emit: __emit }) {

const emit = __emit;
const { operationIsExecuting } = mapGetters();
const store = useStore();
const searchQuery = ref('');
const historyContainer = ref(null);
const historyContent = ref(null);
const isHistoryScrollable = ref(false);
const state = reactive({
  userHistory: [] as Array<FileFromHistory>,
});
__expose({
  getUserHistory,
  updateFileCountResults,
  clearCountResultsFromFile,
});

const filteredHistory = computed(() => {
  const query = searchQuery.value.toLocaleLowerCase();
  if (searchQuery.value === "") {
    return state.userHistory;
  }
  return state.userHistory.filter((file) => {
    return file.name.toLocaleLowerCase().includes(query);
  });
});

onMounted(getUserHistory);

onUpdated(updateHistoryBoxHeight);

async function getUserHistory() {
  const formatPattern = /[0-9a-z]+$/i;
  state.userHistory = await historyApi.getUserCurrentFiles();
  state.userHistory.reverse();
  state.userHistory.forEach((file) => {
    const fileFormat = file.name.match(formatPattern);
    file.fileFormat = `${fileFormat}`;
    file.showHiddenFileDetails = false;
    file.nrPages = "N/A";
    file.nrWords = "N/A";
    file.showSmallSpinners = false;
    store.commit("addShowHistoryModal", false);
  });
}
document.addEventListener("delete-confirmed", (e) => {
  deleteFile((e as CustomEvent).detail);
});
function deleteFile(id: string) {
  emit("remove-file-called-from-history", id);
  deleteFileApi(id);
  analytics.historyTracker.trackDeleteFile();
  state.userHistory.forEach((element, index) => {
    if (element.id === id) {
      state.userHistory.splice(index, 1);
      store.commit("removeShowHistoryModalFromIndex", index);
    }
  });
  updateHistoryBoxHeight();
}

document.addEventListener("delete-all-confirmed", () => {
  deleteAllFiles();
});
function deleteAllFiles() {
  emit("remove-all-files-called-from-history");
  state.userHistory.forEach((element) => {
    deleteFileApi(element.id);
  });
  analytics.historyTracker.trackClearHistory();
  state.userHistory = [];
  store.commit("setAreAllFilesCounted", false);
  isHistoryScrollable.value = false;
}
async function updateFileCountResults(id: string) {
  for (const file of state.userHistory) {
    if (
      file.id === id &&
      file.nrPages === "N/A" &&
      file.nrWords === "N/A"
    ) {
      file.showSmallSpinners = true;
      const words = await countApi.returnCountWordsResult(id);
      const pages = await countApi.returnCountPagesResult(id);
      file.nrPages = pages;
      file.nrWords = words;
      file.showSmallSpinners = false;
    }
  }
}

function clearCountResultsFromFile(id: string) {
  for (const file of state.userHistory) {
    if (file.id === id) {
      file.nrWords = "N/A";
      file.nrPages = "N/A";
    }
  }
}

function updateHistoryBoxHeight() {
  isHistoryScrollable.value = (historyContent.value && historyContent.value.scrollHeight > historyContainer.value.clientHeight - 10) ? true : false;
}
window.visualViewport.addEventListener("resize", updateHistoryBoxHeight);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "history-container",
    ref_key: "historyContainer",
    ref: historyContainer
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "history-title" }, "History", -1)),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
        type: "text",
        placeholder: "Search",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(analytics).historyTracker.trackSearch()))
      }, null, 512), [
        [_vModelText, searchQuery.value]
      ]),
      (searchQuery.value !== '')
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            id: "cancel-search-button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (searchQuery.value = ''))
          }, "✖"))
        : _createCommentVNode("", true),
      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "clear-history-tool-tip" }, "Delete all files", -1)),
      _createElementVNode("button", {
        id: "clear-history-button",
        alt: "Black trash bin icon, with a lid on top, that indicates the action of deleting all history files",
        disabled: !state.userHistory.length || _unref(operationIsExecuting),
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(deleteAllFilesWarningPopup) && _unref(deleteAllFilesWarningPopup)(...args)))
      }, [
        _createVNode(ClearHistoryIcon, {
          class: "icon-color",
          width: "1.5rem",
          height: "1.5rem"
        })
      ], 8, _hoisted_2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["history-content", { scrollable: isHistoryScrollable.value }]),
      ref_key: "historyContent",
      ref: historyContent
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredHistory.value, (file, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: file.id,
            class: "file-info-history"
          }, [
            _createVNode(HistoryFile, {
              file: file,
              index: index,
              onUpdateFileCountResults: updateFileCountResults,
              onUpdateHistoryBoxHeight: updateHistoryBoxHeight
            }, null, 8, ["file", "index"]),
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createVNode(FileLanguage, { file: file }, null, 8, ["file"]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(FileCountResults, { file: file }, null, 8, ["file"]),
                _createVNode(FileActions, {
                  file: file,
                  index: index,
                  userHistoryLength: state.userHistory.length,
                  isHistoryScrollable: isHistoryScrollable.value,
                  onReUse: _cache[4] || (_cache[4] = ($event: any) => (emit('re-use', $event))),
                  onDeleteFile: ($event: any) => (_unref(deleteFileWarningPopup)(file.id))
                }, null, 8, ["file", "index", "userHistoryLength", "isHistoryScrollable", "onDeleteFile"])
              ])
            ], 512), [
              [_vShow, file.showHiddenFileDetails]
            ])
          ]))
        }), 128))
      ]),
      (!state.userHistory.length && !searchQuery.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, " You have no files to display yet. "))
        : (searchQuery.value && !filteredHistory.value.length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, " No results found "))
          : _createCommentVNode("", true)
    ], 2)
  ], 512))
}
}

})