import store from "@/store/store";
import File from '@/interfaces/File';
import FAILED from "@/constants/failed";

export default function calculateTotalCountResults(type: string) {
  return store.getters.uploadedFiles.reduce((total: number, file: File) => {
    if (file.nrWords === FAILED || file.nrPages === FAILED) {
      return total;
    }
    return type === "words" ? total + parseInt(file.nrWords) :
      type === "pages" ? total + parseInt(file.nrPages) :
      total;
  }, 0);
}
