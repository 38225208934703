import { defineComponent as _defineComponent } from 'vue'
import { nextTick } from "vue";
import { useStore } from "vuex";
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async";
import { mapGetters } from "@/store/utils/mapGetters";
import checkOperation from "@/services/checkOperation";
import { analytics } from '@/utils/analitycs';
import countApi from "@/services/countApi";
import FAILED from "@/constants/failed";
import validStatusTypes from "@/constants/validStatusTypes"

type ValidStatus = typeof validStatusTypes[number];


export default /*@__PURE__*/_defineComponent({
  __name: 'CountFiles',
  emits: ["refresh-count-results-for-current-files"],
  setup(__props, { expose: __expose, emit: __emit }) {

const emit = __emit;
const store = useStore();
const {
  uploadedFiles,
  filePagesCounted,
  fileWordsCounted,
} = mapGetters();
__expose({ countFiles });

async function countFiles(
  filesIds: Array<string>,
  countType: "pages" | "words"
) {
  filesIds.forEach(async (fileId, index) => {
    // change index to the last file on the page if function is called from reUse
    if (filesIds.length === 1) {
      index = uploadedFiles.value.length - 1;
    }
    await nextTick();
    store.commit("setUploadedFiles", {
      payload: { progress: 0, status:'Pending'},
      prop: "operationProgress",
      index,
    });
    analytics.countTracker.trackCount();
    const checkIfFileIsNew = setIntervalAsync(async () => {
      const countCheckStatus = await checkFileWasCountedBefore(countType, fileId);
      const countKey = countType === "pages" ? "nrPages" : "nrWords";
      if (countCheckStatus === 404 && !uploadedFiles.value[index][countKey]) {
        clearIntervalAsync(checkIfFileIsNew);
        store.commit("setOperationIsExecuting", true);
        const operationId = await getOperationId(countType, fileId);
        if (!operationId) {
          store.commit("setFileCountRetryToZero", { index });
          handleFailedOperation(fileId, index, 0, countType);
          return;
        }
        await checkFileCountStatus(
          fileId,
          operationId,
          index,
          countType
        );
      } else if (countCheckStatus === 200) {
        clearIntervalAsync(checkIfFileIsNew);
        if (countType === "pages") {
          await countApi.getCountPagesResult(fileId, index);
        } else {
          await countApi.getCountWordsResult(fileId, index);
        }
        await nextTick();
        await isFileCounted(index);
      }
    }, 2000);
  });
}

async function getOperationId(countType: string, fileId: string) {
  return countType && countType === "pages"
    ? await countApi.countPages(fileId) as string
    : await countApi.countWords(fileId) as string;
}

async function checkFileWasCountedBefore(countType: string, fileId: string) {
  return countType && countType === "pages"
    ? await countApi.checkPageCount(fileId)as number
    : await countApi.checkWordCount(fileId)as number;
}

async function checkFileCountStatus(
  fileId: string,
  operationId: string,
  index: number,
  typeOfOperation: "pages" | "words"
) {
  let statusText = "";
  let previousOperationProgress = 0;
  let axiosErrorRetryCounter = 5;
  const operationCall = setIntervalAsync(async () => {
    const currentProgressPercentage = uploadedFiles.value[index].operationProgress.progress;
    const currentOperationStatus = await checkOperation(operationId);
    statusText = currentOperationStatus.status;
    if (!validStatusTypes.includes(statusText as ValidStatus)) {
      handleAxiosError(0, fileId, index);
      clearIntervalAsync(operationCall);
      return;
    }

    if (statusText === "Processing") {
      if (currentOperationStatus.progress > previousOperationProgress) {
        store.commit("setUploadedFiles", {
          payload: {
            progress: currentProgressPercentage + (currentOperationStatus.progress - previousOperationProgress) / 2,
            status: statusText
          },
          prop: "operationProgress",
          index,
        });
      }
      previousOperationProgress = currentOperationStatus.progress;
    }
    if (statusText === "Completed") {
      clearIntervalAsync(operationCall);
      handleCompletedOperation(
        fileId,
        index,
        currentProgressPercentage,
        typeOfOperation
      );
    } else if (statusText === FAILED) {
      await handleFailedOperation(
        fileId,
        index,
        currentProgressPercentage,
        typeOfOperation
      );
      clearIntervalAsync(operationCall);
    } else if (currentOperationStatus.name === "AxiosError") {
      axiosErrorRetryCounter--;
      handleAxiosError(axiosErrorRetryCounter, fileId, index);
      clearIntervalAsync(operationCall);
    }
  }, 5000);
}
async function retryCount(
  fileId: string,
  index: number,
  countType: "pages" | "words"
) {
  const operationId: unknown =
    countType === "pages"
      ? await countApi.countPages(fileId)
      : await countApi.countWords(fileId);
  checkFileCountStatus(fileId, operationId as string, index, countType);
}

async function isFileCounted(index: number) {
  if (
    uploadedFiles.value[index].nrPages &&
    uploadedFiles.value[index].nrWords
  ) {
    store.commit("setUploadedFiles", {
      payload: { progress: 100, status: "Completed"},
      prop: "operationProgress",
      index,
    });
    store.commit("setFileWordsCounted", { payload: true, index });
    store.commit("setFilePagesCounted", { payload: true, index });
    await areAllOperationsFinished();
  }
}

async function areAllOperationsFinished() {
  const areAllWordsCounted = fileWordsCounted.value.every((file: boolean) => file);
  const areAllPagesCounted = filePagesCounted.value.every((file: boolean) => file);
  if (areAllWordsCounted && areAllPagesCounted) {
    store.commit("setAreAllFilesCounted", true);
    store.commit("setOperationIsExecuting", false);
    emit("refresh-count-results-for-current-files");
  }
}

async function handleCompletedOperation(
  fileId: string,
  index: number,
  progressPercentage: number,
  typeOfOperation: "pages" | "words"
) {
  if (typeOfOperation === "words") {
    store.commit("setUploadedFiles", {
      payload: { progress: 90, status: "Processing"},
      prop: "operationProgress",
      index,
    });
    const wordsResult = await countApi.getCountWordsResult(fileId, index);
    if (wordsResult === FAILED) {
      store.commit("setUploadedFiles", {
      payload: FAILED,
      prop: "nrWords",
      index,
    })}
    await isFileCounted(index);
  } else if (typeOfOperation === "pages") {
    store.commit("setUploadedFiles", {
      payload: { progress: progressPercentage > 0 ? progressPercentage : 30, status: "Processing"},
      prop: "operationProgress",
      index,
    });
    const pagesResult = await countApi.getCountPagesResult(fileId, index);
    if (pagesResult === FAILED) {
      store.commit("setUploadedFiles", {
      payload: FAILED,
      prop: "nrPages",
      index,
    })}
    await isFileCounted(index);
  }
}

async function handleFailedOperation(
  fileId: string,
  index: number,
  progressPercentage: number,
  typeOfOperation: "pages" | "words"
) {
  if (
    uploadedFiles.value[index].pagesRetryCount > 0 &&
    typeOfOperation === "pages"
  ) {
    store.commit("decrementFilePagesRetryCount", { index });
    retryCount(fileId, index, typeOfOperation);
  } else if (
    uploadedFiles.value[index].wordsRetryCount > 0 &&
    typeOfOperation === "words"
  ) {
    store.commit("decrementFileWordsRetryCount", { index });
    retryCount(fileId, index, typeOfOperation);
  } else {
    store.commit("setUploadedFiles", {
      payload: { progress: progressPercentage + 50, status: "Processing"},
      prop: "operationProgress",
      index,
    });
    // display an operation failed message instead of the result
    if (typeOfOperation === "words") {
      store.commit("setUploadedFiles", {
        payload: FAILED,
        prop: "nrWords",
        index,
      });
    } else if (typeOfOperation === "pages") {
      store.commit("setUploadedFiles", {
        payload: FAILED,
        prop: "nrPages",
        index,
      });
    }
    await isFileCounted(index);
  }
}

function handleAxiosError(
  axiosErrorRetryCounter: number,
  fileId: string,
  index: number
) {
  if (axiosErrorRetryCounter === 0) {
    store.commit("setUploadedFiles", {
      payload: FAILED,
      prop: "nrWords",
      index,
    });
    store.commit("setUploadedFiles", {
      payload: FAILED,
      prop: "nrPages",
      index,
    });
    isFileCounted(index);
  }
}

return () => {}
}

})