import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]


export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadIconFull',
  props: ['width', 'height'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.width,
    height: __props.height,
    viewBox: "0 0 12 15",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M9.82533 5.5H8.50033V1.33333C8.50033 0.875 8.12533 0.5 7.66699 0.5H4.33366C3.87533 0.5 3.50033 0.875 3.50033 1.33333V5.5H2.17533C1.43366 5.5 1.05866 6.4 1.58366 6.925L5.40866 10.75C5.73366 11.075 6.25866 11.075 6.58366 10.75L10.4087 6.925C10.9337 6.4 10.567 5.5 9.82533 5.5ZM0.166992 13.8333C0.166992 14.2917 0.541992 14.6667 1.00033 14.6667H11.0003C11.4587 14.6667 11.8337 14.2917 11.8337 13.8333C11.8337 13.375 11.4587 13 11.0003 13H1.00033C0.541992 13 0.166992 13.375 0.166992 13.8333Z",
      fill: "#FFF"
    }, null, -1)
  ]), 8, _hoisted_1))
}
}

})