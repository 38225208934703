import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "count-results-container" }
const _hoisted_2 = {
  key: 0,
  class: "small-spinner"
}
const _hoisted_3 = {
  key: 1,
  class: "small-spinner"
}

import FileFromHistory from "@/interfaces/FileFromHistory";


export default /*@__PURE__*/_defineComponent({
  __name: 'FileCountResults',
  props: {
    file: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "result" }, "Pages:  ", -1)),
    _withDirectives(_createElementVNode("span", { id: "pages-result" }, _toDisplayString(props.file.nrPages), 513), [
      [_vShow, !_ctx.file.showSmallSpinners]
    ]),
    (_ctx.file.showSmallSpinners)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "result" }, "Words:  ", -1)),
    _withDirectives(_createElementVNode("span", { id: "words-result" }, _toDisplayString(_ctx.file.nrWords), 513), [
      [_vShow, !_ctx.file.showSmallSpinners]
    ]),
    (_ctx.file.showSmallSpinners)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}
}

})