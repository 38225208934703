// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { saveAs } from 'file-saver';
import axios from 'axios';
import JsZip from 'jszip';
import store from '../store/store';
import FAILED from "@/constants/failed";

export default async function downloadAll() {
  store.commit("setFilesAreDownloading",true);
  const zip = new JsZip();
  const { uploadedFiles } = store.getters;
  const fileNameDuplicate: Array<string> = [];
  for (let i = 0; i < uploadedFiles.length; i++) {
    if (uploadedFiles[i].downloadLink !== FAILED) {
      const currentFileName = uploadedFiles[i].name.substring(
        0,
        uploadedFiles[i].name.lastIndexOf('.'),
      );
      let fileData: Blob = {} as Blob;
      const format = uploadedFiles[i].currentFileFormat.toLowerCase();
      await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_BASE_URL}/api/files/${
          uploadedFiles[i].id
        }?format=${format.toUpperCase()}`,
        responseType: 'blob',
      })
        .then((response) => (fileData = response.data))
        .catch((err) => console.error(err));
      if (fileNameDuplicate.includes(`${currentFileName}.${format}`)) {
        zip.file(
          `(${
            fileNameDuplicate.filter(
              (el) => el === `${currentFileName}.${format}`,
            ).length
          }) ${
            currentFileName
          }.${
            format}`,
          fileData,
        );
      } else {
        zip.file(`${currentFileName}.${format}`, fileData);
      }
      fileNameDuplicate.push(`${currentFileName}.${format}`);
    }
  }
  zip.generateAsync({ type: 'blob' }).then((zip) => {
    saveAs(zip, 'allFiles.zip');
    store.commit("setFilesAreDownloading",false);
  });
}
