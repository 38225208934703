import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-extra-info-history" }
const _hoisted_2 = { id: "history-file-language" }

import FileFromHistory from "@/interfaces/FileFromHistory";
import ISO6391 from "iso-639-1";


export default /*@__PURE__*/_defineComponent({
  __name: 'FileLanguage',
  props: {
    file: {}
  },
  setup(__props: any) {

const props = __props;

function getLanguage(language: string) {
  return ISO6391.getName(language);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _cache[0] || (_cache[0] = _createTextVNode(" Language:   ")),
      _createElementVNode("span", null, _toDisplayString(getLanguage(props.file.languages[0])), 1)
    ])
  ]))
}
}

})