import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/svg/warning.svg'


const _hoisted_1 = { class: "warning-message" }

import store from "@/store/store";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'WarningMessage',
  props: {
    message: {},
    fileWarningMargins: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;
const show = ref(true);

function close() {
  show.value = false;
  if (store.getters.serverIssuesWarning) store.commit("setServerIssuesWarning", false);
}

return (_ctx: any,_cache: any) => {
  return (show.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["warning-container", { 'file-warning-margins': _ctx.fileWarningMargins }])
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_0,
          width: "1.5rem",
          height: "1.5rem",
          alt: "Yellow triangle with a white exclamation mark in the middle",
          draggable: "false"
        }, null, -1)),
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.message), 1),
        _createElementVNode("button", {
          class: "close-popup-button",
          onClick: close
        }, [
          _createVNode(CloseIcon, {
            class: "icon-color",
            width: "1.5rem",
            height: "1.5rem"
          })
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})