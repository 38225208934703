import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
  computed,
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import spinner from './components/LoadingSpinner/LoadingSpinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useStore();
const router = useRouter();
const userEmail = computed(() => store.getters.oidcUser?.email);
const oidcIsAuthenticated = computed(() => store.getters.oidcIsAuthenticated);
const oidcAccessTokenExp = computed(() => store.getters.oidcAccessTokenExp);
const dispatchStore = (name: string) => store.dispatch(name);
const inactive = ref<ReturnType<typeof setTimeout>>();
const root = ref(document.querySelector('html'));

const logoutInactiveUser = () => {
  inactive.value = setTimeout(() => {
    router.push('/logout');
    dispatchStore('signOutOidc');
  }, 1000 * 60 * 1440);
};
const resetTimer = () => {
  clearTimeout(inactive.value);
  logoutInactiveUser();
};
const watchUserInput = () => {
  window.addEventListener('click', resetTimer);
  window.addEventListener('mousemove', resetTimer);
};
const ifTokenIsExpiring = () => {
  const timeNow = new Date().getTime();
  const expirationTime = oidcAccessTokenExp.value;
  const timeRemaining = expirationTime - timeNow;
  return timeRemaining <= 1000 * 60 * 2;
};
onMounted(async () => {
  setPreferredTheme();
  logoutInactiveUser();
  setInterval(() => {
    if (ifTokenIsExpiring()) {
      dispatchStore('authenticateOidcSilent');
    }
  }, 10000);
  watchUserInput();
  await nextTick();
});

function setPreferredTheme() {
  const userPreferredTheme = localStorage.getItem('app-theme-key');
  if (!userPreferredTheme) {
    store.commit("setIsDarkTheme", window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
  } else if (userPreferredTheme === "dark") {
    store.commit("setIsDarkTheme", true);
    root.value.classList.remove('light');
    root.value.classList.toggle('dark');
  } else {
    store.commit("setIsDarkTheme", false);
    root.value.classList.remove('dark');
    root.value.classList.toggle('light');
  }
}

watch(
  () => userEmail.value,
  () => {
    window._paq.push(["setUserId", `${userEmail.value}`]);
  },
  {
    once: true,
  },
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!oidcIsAuthenticated.value && _ctx.$route.path !== '/logout')
      ? (_openBlock(), _createBlock(spinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}
}

})