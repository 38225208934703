import axios from 'axios';
import store from '@/store/store';
import File from '@/interfaces/File';

export default function downloadWatermarkFile(id: string) {
  return axios({
    method: 'GET',
    url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}/watermarked`,
    headers: {
      'Content-Type': 'application/json',
    },
    responseEncoding: 'binary',
    responseType: 'arraybuffer',
  })
    .then((response) => {
      const index = store.getters.uploadedFiles.findIndex((file: File) => file.id === id);
      const blob = new Blob([response.data]);
      const blobUrl = URL.createObjectURL(blob);
      createAndDownloadFileLink(blobUrl, index);
    })
    .catch(console.error);
}

function createAndDownloadFileLink(blobUrl: string, index: number) {
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = store.getters.uploadedFiles[index].name;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
}