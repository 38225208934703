import Swal from "sweetalert2";
import { SweetAlertIcon } from "sweetalert2";
import store from "@/store/store";
import { computed } from 'vue';

const defaultPopup = Swal.mixin({
  customClass: {
    confirmButton: "popupBlueButton",
    popup: 'toast-theme',
    title: "defaultFont",
  },
  buttonsStyling: false,
});
const defaultPopupWithOptions = Swal.mixin({
  customClass: {
    confirmButton: "popupBlueButton",
    cancelButton: "popupGreyButton",
    popup: 'toast-theme',
    title: "defaultFont",
  },
  buttonsStyling: false,
});

export function simplePopup(icon: SweetAlertIcon, title: string) {
  defaultPopup.fire({
    position: "center",
    icon,
    title,
    showConfirmButton: true,
    width: 450,
  });
}
export function countdownPopup(timeInSeconds: number) {
  const currentTheme = computed(() => store.getters.isDarkTheme ? "dark" : "light");
  let countdown = timeInSeconds;

  if (Swal.isVisible()) {
    return;
  }

  defaultPopup.fire({
    html: `<p class = "defaultFont">The server is currently at maximum capacity. <br> Your requests will retry automatically after <strong>${countdown}</strong> seconds.</p>`,
    timer: timeInSeconds * 1000,
    timerProgressBar: true,
    imageUrl: require(`@/svg/refresh-${currentTheme.value}.svg`),
    imageWidth: 100,
    imageHeight: 100,
    showConfirmButton: true,
    didOpen: () => {
      const countdownElement = Swal.getHtmlContainer()?.querySelector('strong');
      const interval = setInterval(() => {
        countdown--;
        if (countdownElement) {
          countdownElement.textContent = countdown.toString();
        }

        if (countdown === 0) {
          clearInterval(interval);
        }
      }, 1000);
    },
  });
}

export function deleteFileWarningPopup(id: string) {
  const deleteConfirmed = new CustomEvent("delete-confirmed", { detail: id });
  if (store.getters.showDeleteFilePopups) {
    defaultPopupWithOptions
      .fire({
        position: "center",
        icon: "question",
        title: "Are you sure you want to delete this file?",
        showConfirmButton: true,
        confirmButtonText: "Yes",
        showCancelButton: true,
        input: "checkbox",
        inputPlaceholder: "<p class='defaultFont'>Don't show this again</p>",
        width: 450,
        inputValidator: (result): any => {
          if (result) {
            store.commit("setShowDeleteFilePopups", false);
          }
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          document.dispatchEvent(deleteConfirmed);
        }
      });
  } else {
    document.dispatchEvent(deleteConfirmed);
  }
}

export function deleteAllFilesWarningPopup() {
  const deleteAllConfirmed = new CustomEvent("delete-all-confirmed");
  defaultPopupWithOptions
    .fire({
      position: "center",
      icon: "question",
      title: "Are you sure you want to delete all files from history?",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      width: 450,
    })
    .then((result) => {
      if (result.isConfirmed) {
        document.dispatchEvent(deleteAllConfirmed);
      }
    });
}
