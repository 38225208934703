import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/svg/Upload.svg'


import { ref } from "vue";
import { useStore } from "vuex";
import { useDropzone } from "vue3-dropzone";
import { mapGetters } from "@/store/utils/mapGetters";
import { analytics } from '@/utils/analitycs';
import { simplePopup } from "@/services/popups";
import formatFileSize from "@/services/formatFileSize";
import File from "@/interfaces/File";


export default /*@__PURE__*/_defineComponent({
  __name: 'FilesDropzone',
  emits: ["update-file-box-height", "send-files"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const { getRootProps, getInputProps } = useDropzone({ onDrop });
const store = useStore();
const isDropboxHighlighted = ref(false);
const currentSessionFiles = ref([]);
const {
  operationFailedRetryCount,
  allSupportedFormats,
  currentLocation
} = mapGetters();

function onDrop(selectedFiles) {
  if (
    (!currentSessionFiles.value.length && selectedFiles.length <= 50) ||
    (currentSessionFiles.value.length + selectedFiles.length <= 50)
  ) {
    const formatPattern = /[0-9a-z]+$/i;
    const unsupportedFileFormats = [];
    store.commit("setAreAllFilesConverted", false);
    const isEveryFilePdf = selectedFiles.every((file) => file.name.toLowerCase().endsWith('.pdf'));
    if(currentLocation.value === "/watermark" && !isEveryFilePdf) {
      simplePopup('info','Watermark feature currently supports PDF files only.');
      return
    }
    selectedFiles.forEach((file, i) => {
      let fileFormat = selectedFiles[i].name.match(formatPattern);
      selectedFiles[i].isFileSupported = true;
      if (
        !allSupportedFormats.value.includes(`${fileFormat}`.toLocaleUpperCase())
      ) {
        fileFormat = "UNKNOWN";
        unsupportedFileFormats.push(
          ` ${selectedFiles[i].name.match(formatPattern)}`
        );
        selectedFiles[i].isFileSupported = false;
      } else {
        store.commit("addUploadedFiles", {
          name: selectedFiles[i].name,
          id: "",
          language: [],
          languageCode: [],
          nrWords: "",
          nrPages: "",
          currentFileFormat: `${fileFormat}`,
          fileSize: formatFileSize(selectedFiles[i].size),
          downloadLink: "",
          convertRetryCount: operationFailedRetryCount.value,
          pagesRetryCount: operationFailedRetryCount.value,
          wordsRetryCount: operationFailedRetryCount.value,
          uploadProgress: 0,
          operationProgress: { progress: 0, status: '' },
          duplicatedFileName: false
        });
      }
    });
    const acceptedFiles = selectedFiles.filter(file => file.isFileSupported);

    if (acceptedFiles.length) {
      currentSessionFiles.value.push(...acceptedFiles);
      emit("send-files", acceptedFiles);
      emit("update-file-box-height");
      trackUploadedFiles();
    }
    if (unsupportedFileFormats.length) {
      const message = `Your selection contains files with unsupported formats: \n \n ${unsupportedFileFormats}`;
      simplePopup('warning', message);
    }

  } else {
    simplePopup('info', 'You can upload a maximum of 50 files');
  }
}

function trackUploadedFiles() {
  if (currentLocation.value === "/convert") {
    analytics.convertTracker.trackUploadLink();
  } else if (currentLocation.value === "/count") {
    analytics.countTracker.trackUploadLink();
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _mergeProps(_unref(getRootProps)(), {
    class: ["drop-box", { 'highlight-dropbox': isDropboxHighlighted.value }],
    onDragover: _cache[1] || (_cache[1] = ($event: any) => (isDropboxHighlighted.value = true)),
    onDragleave: _cache[2] || (_cache[2] = ($event: any) => (isDropboxHighlighted.value = false)),
    onDrop: _cache[3] || (_cache[3] = ($event: any) => (isDropboxHighlighted.value = false))
  }), [
    _createElementVNode("input", _normalizeProps(_guardReactiveProps(_unref(getInputProps)())), null, 16),
    _cache[6] || (_cache[6] = _createElementVNode("img", {
      src: _imports_0,
      draggable: "false",
      alt: "Upload",
      style: {"height":"9rem"}
    }, null, -1)),
    _cache[7] || (_cache[7] = _createElementVNode("p", null, "Drag and drop your files here.", -1)),
    _createElementVNode("p", null, [
      _cache[4] || (_cache[4] = _createTextVNode("Or ")),
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
      }, "click here"),
      _cache[5] || (_cache[5] = _createTextVNode("."))
    ])
  ], 16))
}
}

})