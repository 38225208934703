import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = { opacity: "0.5" }
const _hoisted_3 = [":stroke"]
const _hoisted_4 = ["stroke"]
const _hoisted_5 = ["stroke"]
const _hoisted_6 = ["stroke"]
const _hoisted_7 = ["stroke"]
const _hoisted_8 = ["stroke"]
const _hoisted_9 = ["stroke"]
const _hoisted_10 = ["stroke"]
const _hoisted_11 = ["stroke"]
const _hoisted_12 = ["stroke"]
const _hoisted_13 = ["stroke"]
const _hoisted_14 = ["stroke"]
const _hoisted_15 = ["stroke"]
const _hoisted_16 = ["stroke"]
const _hoisted_17 = ["stroke"]
const _hoisted_18 = ["stroke"]
const _hoisted_19 = ["stroke"]
const _hoisted_20 = ["stroke"]
const _hoisted_21 = ["stroke"]
const _hoisted_22 = ["stroke"]
const _hoisted_23 = ["stroke"]
const _hoisted_24 = ["stroke"]
const _hoisted_25 = ["stroke"]
const _hoisted_26 = ["stroke"]
const _hoisted_27 = ["stroke"]
const _hoisted_28 = ["stroke"]


export default /*@__PURE__*/_defineComponent({
  __name: 'PreviewBackground',
  props: ['width', 'height', 'color'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createElementBlock("svg", {
      viewBox: "0 0 329 456",
      fill: "none",
      width: __props.width,
      height: __props.height,
      xmlns: "http://www.w3.org/2000/svg"
    }, [
      _createElementVNode("g", _hoisted_2, [
        _createElementVNode("line", {
          x1: "32",
          y1: "50",
          x2: "292",
          y2: "50",
          ":stroke": __props.color
        }, null, 8, _hoisted_3),
        _createElementVNode("line", {
          x1: "32",
          y1: "62",
          x2: "292",
          y2: "62",
          stroke: __props.color
        }, null, 8, _hoisted_4),
        _createElementVNode("line", {
          x1: "32",
          y1: "74",
          x2: "292",
          y2: "74",
          stroke: __props.color
        }, null, 8, _hoisted_5),
        _createElementVNode("line", {
          x1: "32",
          y1: "86",
          x2: "292",
          y2: "86",
          stroke: __props.color
        }, null, 8, _hoisted_6),
        _createElementVNode("line", {
          x1: "32",
          y1: "98",
          x2: "292",
          y2: "98",
          stroke: __props.color
        }, null, 8, _hoisted_7),
        _createElementVNode("line", {
          x1: "32",
          y1: "110",
          x2: "292",
          y2: "110",
          stroke: __props.color
        }, null, 8, _hoisted_8),
        _createElementVNode("line", {
          x1: "32",
          y1: "122",
          x2: "292",
          y2: "122",
          stroke: __props.color
        }, null, 8, _hoisted_9),
        _createElementVNode("line", {
          x1: "32",
          y1: "134",
          x2: "292",
          y2: "134",
          stroke: __props.color
        }, null, 8, _hoisted_10),
        _createElementVNode("line", {
          x1: "32",
          y1: "146",
          x2: "292",
          y2: "146",
          stroke: __props.color
        }, null, 8, _hoisted_11),
        _createElementVNode("line", {
          x1: "32",
          y1: "158",
          x2: "292",
          y2: "158",
          stroke: __props.color
        }, null, 8, _hoisted_12),
        _createElementVNode("line", {
          x1: "32",
          y1: "170",
          x2: "292",
          y2: "170",
          stroke: __props.color
        }, null, 8, _hoisted_13),
        _createElementVNode("line", {
          x1: "32",
          y1: "182",
          x2: "292",
          y2: "182",
          stroke: __props.color
        }, null, 8, _hoisted_14),
        _createElementVNode("line", {
          x1: "32",
          y1: "194",
          x2: "292",
          y2: "194",
          stroke: __props.color
        }, null, 8, _hoisted_15),
        _createElementVNode("line", {
          x1: "32",
          y1: "206",
          x2: "292",
          y2: "206",
          stroke: __props.color
        }, null, 8, _hoisted_16),
        _createElementVNode("line", {
          x1: "32",
          y1: "268.5",
          x2: "292",
          y2: "268.5",
          stroke: __props.color
        }, null, 8, _hoisted_17),
        _createElementVNode("line", {
          x1: "32",
          y1: "280.5",
          x2: "292",
          y2: "280.5",
          stroke: __props.color
        }, null, 8, _hoisted_18),
        _createElementVNode("line", {
          x1: "32",
          y1: "292.5",
          x2: "292",
          y2: "292.5",
          stroke: __props.color
        }, null, 8, _hoisted_19),
        _createElementVNode("line", {
          x1: "32",
          y1: "304.5",
          x2: "292",
          y2: "304.5",
          stroke: __props.color
        }, null, 8, _hoisted_20),
        _createElementVNode("line", {
          x1: "32",
          y1: "316.5",
          x2: "292",
          y2: "316.5",
          stroke: __props.color
        }, null, 8, _hoisted_21),
        _createElementVNode("line", {
          x1: "32",
          y1: "328.5",
          x2: "292",
          y2: "328.5",
          stroke: __props.color
        }, null, 8, _hoisted_22),
        _createElementVNode("line", {
          x1: "32",
          y1: "340.5",
          x2: "292",
          y2: "340.5",
          stroke: __props.color
        }, null, 8, _hoisted_23),
        _createElementVNode("line", {
          x1: "32",
          y1: "352.5",
          x2: "292",
          y2: "352.5",
          stroke: __props.color
        }, null, 8, _hoisted_24),
        _createElementVNode("line", {
          x1: "32",
          y1: "364.5",
          x2: "292",
          y2: "364.5",
          stroke: __props.color
        }, null, 8, _hoisted_25),
        _createElementVNode("line", {
          x1: "32",
          y1: "376.5",
          x2: "292",
          y2: "376.5",
          stroke: __props.color
        }, null, 8, _hoisted_26),
        _createElementVNode("line", {
          x1: "32",
          y1: "388.5",
          x2: "292",
          y2: "388.5",
          stroke: __props.color
        }, null, 8, _hoisted_27),
        _createElementVNode("line", {
          x1: "32",
          y1: "400.5",
          x2: "292",
          y2: "400.5",
          stroke: __props.color
        }, null, 8, _hoisted_28)
      ])
    ], 8, _hoisted_1))
  ]))
}
}

})