import MatomoCategory from '@/enums/matomo/MatomoCategory';
import MatomoHistory from '@/enums/matomo/MatomoHistory';
import { AnalyticsService } from '@/services/analyticsService';

export class HistoryTracker {
  private analytics: AnalyticsService;

  constructor(analytics: AnalyticsService) {
    this.analytics = analytics;
  }

  private track(action: string, event?: string): void {
    this.analytics.trackEvent({
      category: MatomoCategory.HISTORY,
      action,
      event,
    });
  }

  public trackSearch() {
    this.track(MatomoHistory.SEARCH);
  }

  public trackClearHistory() {
    this.track(MatomoHistory.CLEAR_HISTORY);
  }

  public trackPreview() {
    this.track(MatomoHistory.PREVIEW);
  }


  public trackDownloadFileAs(format: string) {
    this.track(MatomoHistory.DOWNLOAD_AS, format);
  }

  public trackReuse() {
    this.track(MatomoHistory.REUSE);
  }

  public trackDeleteFile() {
    this.track(MatomoHistory.DELETE_FILE);
  }
}
