import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "info-failed"
}
const _hoisted_3 = {
  key: 1,
  class: "row"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 1,
  class: "small-spinner"
}

import { ref, computed } from "vue";
import FileViewer from "@/components/FileViewer/FileViewer.vue";
import OnlyOffice from "@/components/FileViewer/OnlyOffice/OnlyOffice.vue";
import { mapGetters } from "@/store/utils/mapGetters";
import downloadWatermarkFile from "@/services/watermarks/downloadWatermarkFile";
import DownloadIconFull from "@/components/icons/DownloadIconFull.vue";
import FAILED from "@/constants/failed";


export default /*@__PURE__*/_defineComponent({
  __name: 'WatermarkResults',
  props: {
    index: {}
  },
  setup(__props: any) {

const props = __props;
const isFileDownloading = ref(false);
const {
  uploadedFiles,
  uploadedFilesIds,
  isWatermarkOperationStarted,
  operationIsExecuting
} = mapGetters();

const fileWatermarkFailed = computed(
  () => uploadedFiles.value[props.index].downloadLink === FAILED
);

async function downloadFile(id: string) {
  isFileDownloading.value = true;
  await downloadWatermarkFile(id);
  isFileDownloading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_unref(isWatermarkOperationStarted) &&
    !_unref(operationIsExecuting))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (fileWatermarkFailed.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Operation Failed "))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(FileViewer, {
                "file-name": _unref(uploadedFiles)[_ctx.index].name,
                index: _ctx.index,
                useIconButton: false
              }, {
                default: _withCtx(() => [
                  _createVNode(OnlyOffice, { index: _ctx.index }, null, 8, ["index"])
                ]),
                _: 1
              }, 8, ["file-name", "index"]),
              _createElementVNode("button", {
                id: "download-file-button",
                class: "custom-blue-button",
                disabled: isFileDownloading.value,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (downloadFile(_unref(uploadedFilesIds)[_ctx.index])))
              }, [
                (!isFileDownloading.value)
                  ? (_openBlock(), _createBlock(DownloadIconFull, {
                      key: 0,
                      width: "0.75rem",
                      height: "0.75rem"
                    }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5)),
                _cache[1] || (_cache[1] = _createTextVNode(" Download "))
              ], 8, _hoisted_4)
            ]))
      ]))
    : _createCommentVNode("", true)
}
}

})