import axios, { AxiosProgressEvent } from "axios";
import store from "@/store/store";
import FileToBeUploaded from "@/interfaces/FileToBeUploaded";

export default async function uploadFiles(
  filesToBeUploaded: Array<FileToBeUploaded>
) {
  const { currentLocation } = store.getters;
  store.commit("setOperationIsExecuting", true);
  store.commit("setAreAllFilesUploaded", false);
  store.commit("resetAvailableFormats");
  filesToBeUploaded.forEach(() => {
    store.commit("addFilePagesCounted", false);
    store.commit("addFileWordsCounted", false);
    store.commit("addFileWasConverted", false);
  });
  if (currentLocation === "/convert" || currentLocation === "/watermark") {
    handleConvertOrWatermarkRoute(filesToBeUploaded);
  } else {
    handleCountRoute(filesToBeUploaded);
  }
}

async function handleConvertOrWatermarkRoute(filesToBeUploaded: Array<FileToBeUploaded>) {
  const { uploadedFilesIds, currentLocation } = store.getters;
  const fileIds: Array<string> = [];
  try {
    const uploadPromises = filesToBeUploaded.map(async (file: FileToBeUploaded, i: number) => {
      const index = i + uploadedFilesIds.length;
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/api/files?language=en`,
        [file],
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const total = progressEvent.total || progressEvent.loaded;
            store.commit("setUploadedFiles", {
              payload: Math.round((progressEvent.loaded / total) * 100),
              prop: "uploadProgress",
              index,
            });
          }
        }
      );
      const fileIdMap = response.data;
      const fileName = file.name;
      const fileId = fileIdMap[fileName];
      fileIds.push(fileId[0]);
      if (fileId) {
        store.commit("setUploadedFiles", {
          payload: fileId[0],
          prop: "id",
          index
        });
        store.commit("setUploadedFilesIds", {
          payload: fileId[0],
          index,
        });
      }
    });
    await Promise.all(uploadPromises);

    store.commit("setAreAllFilesUploaded", true);
    if (currentLocation === "/convert") {
      store.commit("setShowLanguageComponent", true);
    } else {
      store.commit("setOperationIsExecuting", false);
    }
  } catch (err) {
    console.error(err);
  }
}

async function handleCountRoute(filesToBeUploaded: Array<FileToBeUploaded>) {
  const { uploadedFiles } = store.getters;
  store.commit("setShowLanguageComponent", false);
  try {
    const uploadPromises = filesToBeUploaded.map(async (file: FileToBeUploaded, index: number) => {
      const languageCodeQuery = uploadedFiles[index].languageCode.map((code: string) => `language=${code}`).join('&');
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/api/files?${languageCodeQuery}`,
        [file],
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const total = progressEvent.total || progressEvent.loaded;
            store.commit("setUploadedFiles", {
              payload: Math.round((progressEvent.loaded / total) * 100),
              prop: "uploadProgress",
              index,
            });
          }
        }
      );
      const fileIdMap = response.data;
      const fileName = file.name;
      const fileId = fileIdMap[fileName];
      if (fileId) {
        store.commit("setUploadedFiles", {
          payload: fileId[0],
          prop: "id",
          index,
        });
        store.commit("setUploadedFilesIds", {
          payload: fileId[0],
          index,
        });
      }
    });
    await Promise.all(uploadPromises);
    store.commit("setAreAllFilesUploaded", true);
  } catch (err) {
    console.error(err);
  }
}