import MatomoCategory from '@/enums/matomo/MatomoCategory';
import MatomoWatermark from '@/enums/matomo/MatomoWatermark';
import { AnalyticsService } from '@/services/analyticsService';

export class WatermarkTracker {
  private analytics: AnalyticsService;

  constructor(analytics: AnalyticsService) {
    this.analytics = analytics;
  }

  private track(action: string, event?: string): void {
    this.analytics.trackEvent({
      category: MatomoCategory.WATERMARK,
      action,
      event,
    });
  }

  public trackWatermarkApply() {
    this.track(MatomoWatermark.APPLY);
  }

  public trackAddFiles() {
    this.track(MatomoWatermark.ADD_FILES);
  }

  public trackEditAll() {
    this.track(MatomoWatermark.EDIT_ALL);
  }

  public trackWatermarkCancel() {
    this.track(MatomoWatermark.CANCEL);
  }

  public trackDownload() {
    this.track(MatomoWatermark.DOWNLOAD);
  }

  public trackPreview() {
    this.track(MatomoWatermark.PREVIEW);
  }

  public trackRemoveFile() {
    this.track(MatomoWatermark.REMOVE_FILE);
  }

  public trackStartOver() {
    this.track(MatomoWatermark.START_OVER);
  }
}
