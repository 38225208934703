import File from './../interfaces/File'

export const state = {
  availableFormats: [] as Array<string>,
  selectedFormat: '' as string,
  languageList: '' as string,
  showLanguageComponent: false,
  showProgressBar: true,
  uploadedFiles: [] as Array<File>,
  uploadedFilesIds: [] as Array<string>,
  fileWasConverted: [] as Array<boolean>,
  filePagesCounted: [] as Array<boolean>,
  fileWordsCounted: [] as Array<boolean>,
  showHistoryModal: [] as Array<boolean>,
  operationIsExecuting: false,
  isLanguageDropdownVisible: [] as Array<boolean>,
  areAllFilesUploaded: false,
  areAllFilesConverted: false,
  areAllFilesCounted: false,
  isCountOperationStarted: false,
  isWatermarkOperationStarted: false,
  showDeleteFilePopups: true,
  currentLocation: '' as string,
  filesAreDownloading: false,
  resetGlobalSelector: false,
  isDarkTheme: false,
  serverIssuesWarning: false,
  operationFailedRetryCount: 3,
  allSupportedFormats: [
    'BMP',
    'CSS',
    'CSV',
    'DOC',
    'DOCM',
    'DOCX',
    'DOT',
    'DOTM',
    'DOTX',
    'EML',
    'EMLX',
    'GIF',
    'HTM',
    'HTML',
    'JPEG',
    'JPG',
    'MHT',
    'MHTML',
    'MOBI',
    'MSG',
    'NUMBERS',
    'ODP',
    'ODS',
    'ODT',
    'OPC',
    'OTP',
    'OTT',
    'PDF',
    'PNG',
    'POTM',
    'POTX',
    'PPS',
    'PPSM',
    'PPSX',
    'PPT',
    'PPTM',
    'PPTX',
    'PSB',
    'PSD',
    'RTF',
    'TGA',
    'TIF',
    'TIFF',
    'TSV',
    'TXT',
    'WEBP',
    'XLS',
    'XLSM',
    'XLSX',
    'XML',
    'XPS',
    'JSON',
  ] as Array<string>,
}