import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "format-select-container" }
const _hoisted_2 = { class: "ocr-button-container" }
const _hoisted_3 = { class: "tooltip" }
const _hoisted_4 = ["disabled"]

import { ref, computed, nextTick } from "vue";
import { useStore } from "vuex";
import ToggleSwitch from "@/components/ToggleSwitch/ToggleSwitch.vue";
import FormatSelect from "@/components/FormatSelect/FormatSelect.vue";
import GlobalLanguageSelect from "@/components/LanguageSelect/GlobalLanguageSelector.vue";
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async";
import { mapGetters } from "@/store/utils/mapGetters";
import convertApi from "@/services/convertApi";
import checkOperation from "@/services/checkOperation";
import setLanguages from "@/services/setLanguages";
import File from "@/interfaces/File";
import FAILED from "@/constants/failed";
import validStatusTypes from "@/constants/validStatusTypes"
import { analytics } from "@/utils/analitycs";

type ValidStatus = typeof validStatusTypes[number];


export default /*@__PURE__*/_defineComponent({
  __name: 'ConvertFiles',
  emits: ["remove-dropbox"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const store = useStore();
const isConvertRunning = ref<boolean>(false);
const enableOcr = ref<boolean>(true);
const {
  operationIsExecuting,
  areAllFilesConverted,
  isFormatSelected,
  uploadedFilesIds,
  selectedFormat,
  fileWasConverted,
  uploadedFiles,
  showProgressBar,
  showLanguageComponent
} = mapGetters();

const isLanguageAndFormatSelected = computed(() => uploadedFiles
  .value.every((file: File) => file.language.length > 0)
  && isFormatSelected.value);

const showGlobalLanguage = computed(() => uploadedFilesIds.value.length > 1 && (showLanguageComponent.value || operationIsExecuting.value));

async function convertFiles() {
  await setLanguages();
  trackConvert();
  const filesIds: Array<string> = uploadedFilesIds.value;
  const format: string = selectedFormat.value;
  const filesToBeConverted: Array<string> = [];
  emit("remove-dropbox");
  await nextTick();
  store.commit("setAreAllFilesConverted", false);
  store.commit("setOperationIsExecuting", true);
  store.commit("setShowLanguageComponent", false);
  isConvertRunning.value = true;
  filesIds.forEach((id, index) => {
    store.commit("setUploadedFiles", {
      payload: { progress: 0, status:'Pending'},
      prop: "operationProgress",
      index,
    });
  });
  for (const id of filesIds) {
    const index = filesIds.indexOf(id);
    const checkFileWasConverted = await convertApi.checkFileWasConverted(id, format);
    if (checkFileWasConverted === 200) {
      store.commit("setFileWasConverted", { payload: true, index });
      changeFileExtension(id, index, format);
      convertApi.getFileSize(id, format);
      store.commit("setUploadedFiles", {
        payload: format,
        prop: "currentFileFormat",
        index,
      });
    } else {
      store.commit("setFileWasConverted", { payload: false, index });
      filesToBeConverted.push(id);
    }
  }
  if (filesToBeConverted.length) {
    startConvert(filesToBeConverted, format);
    return;
  }
  checkIfAllFilesWereConverted();
}

async function startConvert(
  filesToBeConverted: Array<string>,
  format: string
) {
  const fileOperationMap = await convertApi.convertFiles(
    filesToBeConverted,
    format,
    !enableOcr.value
  );
  if (fileOperationMap.error) {
    setUnprocessableFilesToFailed(fileOperationMap.data);
    const filteredFiles = filesToBeConverted.filter(item => !fileOperationMap.data.includes(item));
    filteredFiles.length && startConvert(filteredFiles, format);
    return;
  }
  const operationsIds = Object.values<string>(fileOperationMap);
  const filesIds = Object.keys(fileOperationMap);
  operationsIds.forEach(async (operationId: string, index: number) => {
    checkFileConvertStatus(
      operationId,
      format,
      filesIds[index]
    );
  });
}
async function retryConvertOnFile(fileId: string, format: string) {
  const fileOperationMap = await convertApi.convertFiles(
    [fileId],
    format,
    !enableOcr.value
  );
  const operationId = Object.values(fileOperationMap).join(",");
  checkFileConvertStatus(
    operationId,
    format,
    fileId
  );
}

function checkIfStatusIsValid(currentStatus: string, progressStatus: string, index: number) {
  if (validStatusTypes.includes(currentStatus as ValidStatus)) {
    store.commit("setUploadedFiles", {
      payload: { progress: progressStatus, status: currentStatus},
      prop: "operationProgress",
      index,
    });
    return true;
  } else {
    store.commit("setUploadedFiles", {
      payload: { progress: 100, status: FAILED},
      prop: "operationProgress",
      index,
    });
    operationCompleted(index, FAILED, "downloadLink");
    return false;
  }
}

async function checkFileConvertStatus(
  operationId: string,
  format: string,
  fileId: string
) {
  let axiosErrorRetryCounter = 5;
  const index = uploadedFiles.value.findIndex((file: File) => file.id === fileId);
  const operationCall = setIntervalAsync(async () => {
    const { status: currentStatus, progress: progressStatus, name } = await checkOperation(operationId);
    const isValidStatus = checkIfStatusIsValid(currentStatus, progressStatus, index);
    if (!isValidStatus) {
      clearIntervalAsync(operationCall);
      return;
    }
    if (currentStatus === "Completed") {
      changeFileExtension(fileId, index, format);
      convertApi.getFileSize(fileId, format);
      clearIntervalAsync(operationCall);
      store.commit("setUploadedFiles", {
        payload: { progress: 100, status: currentStatus},
        prop: "operationProgress",
        index,
      });
      operationCompleted(index, format, "currentFileFormat");
    } else if (currentStatus === FAILED) {
      if (uploadedFiles.value[index].convertRetryCount > 0) {
        store.commit("setUploadedFiles", {
          payload: { progress: 0, status: "Retrying"},
          prop: "operationProgress",
          index,
        });
        store.commit("decrementFileConvertRetryCount", { index });
        clearIntervalAsync(operationCall);
        retryConvertOnFile(fileId, format);
      } else {
        store.commit("setUploadedFiles", {
          payload: { progress: 100, status: currentStatus},
          prop: "operationProgress",
          index,
        });
        operationCompleted(index, FAILED, "downloadLink");
        clearIntervalAsync(operationCall);
      }
    } else if (name === "AxiosError") {
      axiosErrorRetryCounter--;
      if (axiosErrorRetryCounter === 0) {
        store.commit("setUploadedFiles", {
          payload: { progress: 100, status: FAILED},
          prop: "operationProgress",
          index,
        });
        operationCompleted(index, FAILED, "downloadLink");
        clearIntervalAsync(operationCall);
      }
    }
  }, 3000);
}

function checkIfAllFilesWereConverted() {
  const areAllFilesConverted: boolean = fileWasConverted.value.every((file: boolean) => file === true);
  store.commit("setAreAllFilesConverted", areAllFilesConverted);
  if (areAllFilesConverted) {
    resetHistoryAvailableFormats();
    isConvertRunning.value = false;
    store.commit("setOperationIsExecuting", false);
  }
}

function resetHistoryAvailableFormats() {
  const availableFormatsContainer = document.querySelectorAll(
    ".availableFormatsContainer"
  );
  availableFormatsContainer.forEach((modal, index) => {
    store.commit("setShowHistoryModal", {
      payload: false,
      index,
    });
  });
}

function operationCompleted(index: number, payload: string, prop: string) {
  store.commit("setFileWasConverted", { payload: true, index });
  store.commit("setUploadedFiles", { payload, prop, index });
  checkIfAllFilesWereConverted();
}

function changeFileExtension(fileId: string, index: number, format: string) {
  const currentFile = uploadedFiles.value.find((file: File) => file.id === fileId);
  const fileNewExtension = currentFile?.name.replace(/\.[^.]+$/, `.${format.toLowerCase()}`);
  store.commit("setUploadedFiles", {
    payload: fileNewExtension,
    prop: "name",
    index,
  });
}

function setUnprocessableFilesToFailed(unprocessableFiles: string[]) {
  uploadedFiles.value.forEach((fileId: string, index: number) => {
    if(unprocessableFiles.includes(fileId)) {
      operationCompleted(index, FAILED, "downloadLink");
    }
  })
}

function trackConvert() {
  enableOcr.value === true ? analytics.convertTracker.trackOcrToggle("Enabled") : analytics.convertTracker.trackOcrToggle("Disabled");
  analytics.convertTracker.trackConvert();
}

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["convert-to-box", { disabled: _unref(operationIsExecuting) }])
  }, [
    _withDirectives(_createVNode(GlobalLanguageSelect, null, null, 512), [
      [_vShow, showGlobalLanguage.value]
    ]),
    _createElementVNode("div", _hoisted_1, [
      (!_unref(areAllFilesConverted))
        ? (_openBlock(), _createBlock(FormatSelect, {
            key: 0,
            class: _normalizeClass({ disabled: !_unref(showProgressBar) })
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "tooltip-text" }, " OCR is the process of extracting text from scanned documents and images. ", -1)),
        _createVNode(ToggleSwitch, {
          modelValue: enableOcr.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((enableOcr).value = $event)),
          label: "OCR"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("button", {
      id: "convert-button",
      class: "blue-button",
      disabled: !isLanguageAndFormatSelected.value,
      onClick: convertFiles
    }, " Convert ", 8, _hoisted_4)
  ], 2)), [
    [_vShow, 
      !_unref(areAllFilesConverted) &&
      !isConvertRunning.value &&
      _unref(uploadedFilesIds).length
    ]
  ])
}
}

})