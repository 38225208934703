import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "files-section" }
const _hoisted_2 = { class: "uploaded-files-container" }
const _hoisted_3 = { class: "operations-steps-box" }
const _hoisted_4 = {
  key: 0,
  class: "row"
}
const _hoisted_5 = {
  key: 1,
  class: "row"
}
const _hoisted_6 = { class: "options-container" }
const _hoisted_7 = { class: "file-headers" }
const _hoisted_8 = {
  key: 0,
  id: "language-header"
}
const _hoisted_9 = { class: "history-section" }

import {
  ref,
  computed,
  nextTick,
  onMounted,
  onUpdated,
  watch,
  provide
} from "vue";
import { useStore } from "vuex";
import { mapGetters } from "@/store/utils/mapGetters";
import { simplePopup } from "@/services/popups";
import formatFileSize from "@/services/formatFileSize";
import getLanguages from "@/services/getLanguages";
import uploadFiles from "@/services/uploadFiles";
import getFormats from "@/services/getFormats";
import { analytics } from '@/utils/analitycs';
import setProcessButtonText from "@/services/setProcessButtonText";
import FileToBeUploaded from "@/interfaces/FileToBeUploaded";
import ReuseFile from "@/interfaces/ReuseFile";
import ProcessButton from "@/components/ProcessButton/ProcessButton.vue";
import FilesDropzone from "@/components/FilesDropzone/FilesDropzone.vue";
import FileDetails from "@/components/FileComponent/FileComponent.vue";
import CountFiles from "@/components/CountFiles/CountFiles.vue";
import ConvertFiles from "@/components/ConvertFiles/ConvertFiles.vue";
import TotalCountResults from "@/components/CountFiles/TotalCountResults.vue";
import UserFileHistory from "@/components/UserHistory/UserHistory.vue";
import PageSwitch from "@/components/ThePageSwitch/ThePageSwitch.vue";
import GlobalLanguageSelect from "@/components/LanguageSelect/GlobalLanguageSelector.vue";
import WarningMessage from "@/components/WarningMessage/WarningMessage.vue";
import WelcomeMessage from "@/components/WelcomeMessage/WelcomeMessage.vue";
import OptionsAfterCount from "@/components/OptionsAfterOperations/OptionsAfterCount.vue";
import OptionsAfterConvert from "@/components/OptionsAfterOperations/OptionsAfterConvert.vue";
import OptionsAfterWatermark from "@/components/OptionsAfterOperations/OptionsAfterWatermark.vue";
import WatermarkSettings from "@/components/WatermarkSettings/WatermarkSettings.vue"
import FeedbackSurveyLink from "@/components/FeedbackSurveyLink/FeedbackSurveyLink.vue";
import File from "@/interfaces/File";
import FAILED from "@/constants/failed";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainContainer',
  setup(__props) {

const files = ref<Array<FileToBeUploaded>>([]);
const showDropbox = ref<boolean>(true);
const userFileHistoryRef = ref();
const countComponentRef = ref();
const store = useStore();
const isScrollable = ref<boolean>(false);
const screenHeight = ref(window.innerHeight);
const screenWidth = ref(window.innerWidth);
const fileBox = ref(null);
let fileNamesPushed  = {};
const {
  areFilesUploaded,
  areFilesIds,
  currentLocation,
  operationIsExecuting,
  operationFailedRetryCount,
  areAllFilesUploaded,
  areAllFilesConverted,
  uploadedFilesIds,
  isWatermarkOperationStarted,
  showLanguageComponent,
  uploadedFiles,
  areAllFilesCounted,
  serverIssuesWarning,
} = mapGetters();
provide("isScrollable", isScrollable);

onMounted(async () => {
  reset();
  await getLanguages();
  window.addEventListener('resize', () => {
    updateScreenHeight();
    screenWidth.value = window.innerWidth;
  });
});

onUpdated(updateFileBoxHeight);

const applyLowResHeight = computed(() => {
  return screenWidth.value <= 1500 &&
    ((currentLocation.value === '/watermark' && isWatermarkOperationStarted.value) ||
    (currentLocation.value !== '/watermark' && !showDropbox.value));
});

const applyWatermarkHeight = computed(() => currentLocation.value === '/watermark' && !isWatermarkOperationStarted.value);

function addFilesAndDetectDuplicates(newFiles) {
  newFiles.forEach(newFile => {
    files.value.push(newFile);
    if(fileNamesPushed[newFile.name]) {
      store.commit("setUploadedFiles", {
        payload: true,
        prop: "duplicatedFileName",
        index: files.value.length - 1,
      });
      simplePopup("info", "Potential duplicate files detected during upload!");
    }
    fileNamesPushed[newFile.name] = true;
  });
}

function uploadNewFiles(newFiles: Event): void {
  addFilesAndDetectDuplicates(newFiles);
  if(currentLocation.value === "/convert") {
    store.commit("setShowLanguageComponent", false);
    uploadFiles(newFiles);
  } else if (currentLocation.value === "/count") {
    store.commit("setShowLanguageComponent", true);
  } else {
    showDropbox.value = false;
    uploadFiles(newFiles);
    updateFileBoxHeight();
  }
}

watch(areAllFilesUploaded, (newValue) => {
  if (newValue === true && currentLocation.value === "/convert") {
    store.commit("setOperationIsExecuting",false);
  } else if (newValue === true && currentLocation.value === "/count") {
    countComponentRef.value.countFiles(uploadedFilesIds.value, "pages");
    countComponentRef.value.countFiles(uploadedFilesIds.value, "words");
    userFileHistoryRef.value.getUserHistory();
  } else {
    return;
  }
});

watch(areAllFilesConverted, (newValue) => newValue && currentLocation.value === "/convert" && userFileHistoryRef.value.getUserHistory());

watch(isWatermarkOperationStarted, (newValue) => newValue && userFileHistoryRef.value.getUserHistory());

async function updateFileBoxHeight() {
  store.commit("setAllLanguageDropdownsToFalse");
  await nextTick();
  isScrollable.value = (fileBox.value && fileBox.value.scrollHeight > fileBox.value.clientHeight) ? true : false;
}

function updateScreenHeight () {
  screenHeight.value = window.innerHeight;
}

async function removeFileFromPage(index: number) {
  files.value.splice(index, 1);
  store.commit("removeItemFromIndex", index);
  trackRemoveFile();
  if (files.value.length === 0) {
    store.commit("resetFileValues");
    showDropbox.value = true;
  }
  if (
    currentLocation.value === "/convert" &&
    uploadedFilesIds.value.length > 0 &&
    !areAllFilesConverted.value
  ) {
    store.commit("setShowProgressBar", false);
    getFormats(uploadedFilesIds.value);
  }
  updateFileBoxHeight();
}

async function removeFileCalledFromHistory(id: string) {
  trackRemoveFile();
  if (files.value !== undefined) {
    uploadedFilesIds.value.forEach(
      (element: string, index: number) =>
        element === id && removeFileFromPage(index)
    );
  }
  if (files.value.length === 0) {
    reset();
  }
}

async function removeAllFilesCalledFromHistory() {
  if (areFilesIds) {
    reset();
  }
}

async function reUseFile(file: ReuseFile, language: string) {
  if (files.value === undefined) {
    reset();
  }
  if (!uploadedFilesIds.value.includes(file.id)) {
    store.commit("setShowLanguageComponent", false);
    store.commit("setAreAllFilesUploaded", true);
    setProcessButtonText();
    updateFileBoxHeight();
    files.value.push(file);
    showDropbox.value = false;
    store.commit("addUploadedFilesId", file.id);
    store.commit("addUploadedFiles", {
      name: file.name,
      id: file.id,
      language: [language],
      languageCode: [],
      nrWords: "",
      nrPages: "",
      currentFileFormat: file.fileFormat,
      fileSize: formatFileSize(file.size),
      downloadLink: "",
      isFileSupported: true,
      convertRetryCount: operationFailedRetryCount.value,
      pagesRetryCount: operationFailedRetryCount.value,
      wordsRetryCount: operationFailedRetryCount.value,
      uploadProgress: 0,
      operationProgress: { progress: 0, status: '' },
      duplicatedFileName: false
    });

    if (currentLocation.value === "/convert") {
      store.commit("addFileWasConverted", false);
    } else if (currentLocation.value === "/count") {
      store.commit("addFilePagesCounted", false);
      store.commit("addFileWordsCounted", false);
      countComponentRef.value.countFiles([file.id], "pages");
      countComponentRef.value.countFiles([file.id], "words");
    }
  } else {
    simplePopup("info", "This file is already in use!");
  }
}

function reset() {
  store.commit("resetFileValues");
  store.commit("setResetGlobalSelector", true);
  files.value = [];
  fileNamesPushed = {};
  showDropbox.value = true;
}

function refreshCountResultsForCurrentFiles() {
  uploadedFilesIds.value.forEach((id: string) => {
    userFileHistoryRef.value.clearCountResultsFromFile(id);
    userFileHistoryRef.value.updateFileCountResults(id);
  });
}

function areOperationsFailed(): boolean {
  return uploadedFiles.value.some((file: File) => file.nrPages === FAILED || file.nrWords === FAILED);
}

function trackRemoveFile() {
  if (currentLocation.value === "/convert") {
    analytics.convertTracker.trackRemoveFile();
  } else if (currentLocation.value === "/count") {
    analytics.countTracker.trackRemoveFile();
  } else if (currentLocation.value === "/watermark") {
    analytics.watermarkTracker.trackRemoveFile();
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(serverIssuesWarning))
      ? (_openBlock(), _createBlock(WarningMessage, {
          key: 0,
          message: "A2A is currently encountering server issues, which might result in some actions being unable to be completed. Thank you for your patience."
        }))
      : _createCommentVNode("", true),
    _createElementVNode("main", null, [
      _createElementVNode("section", _hoisted_1, [
        _createVNode(PageSwitch),
        (showDropbox.value)
          ? (_openBlock(), _createBlock(FilesDropzone, {
              key: 0,
              onUpdateFileBoxHeight: updateFileBoxHeight,
              onSendFiles: uploadNewFiles
            }))
          : _createCommentVNode("", true),
        _createVNode(WelcomeMessage),
        _withDirectives(_createVNode(WatermarkSettings, {
          onReset: reset,
          onAddMoreFiles: uploadNewFiles
        }, null, 512), [
          [_vShow, _unref(currentLocation) === '/watermark' &&
          _unref(areFilesUploaded) &&
          !_unref(isWatermarkOperationStarted)]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_unref(currentLocation) === '/convert' && screenHeight.value > 800)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_4, " Convert files "))
              : (_unref(currentLocation) === '/count')
                ? (_openBlock(), _createElementBlock("h2", _hoisted_5, " Count pages and words "))
                : _createCommentVNode("", true),
            (_unref(currentLocation) === '/convert')
              ? (_openBlock(), _createBlock(ConvertFiles, {
                  key: 2,
                  onRemoveDropbox: _cache[0] || (_cache[0] = ($event: any) => (showDropbox.value = false))
                }))
              : _createCommentVNode("", true),
            (_unref(currentLocation) === '/count')
              ? (_openBlock(), _createBlock(CountFiles, {
                  key: 3,
                  onRefreshCountResultsForCurrentFiles: 
              refreshCountResultsForCurrentFiles
            ,
                  ref_key: "countComponentRef",
                  ref: countComponentRef
                }, null, 8, ["onRefreshCountResultsForCurrentFiles"]))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("div", _hoisted_6, [
              _withDirectives(_createVNode(GlobalLanguageSelect, null, null, 512), [
                [_vShow, _unref(showLanguageComponent) && files.value.length > 1]
              ]),
              _createVNode(ProcessButton, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(uploadFiles)(files.value), showDropbox.value = false))
              })
            ], 512), [
              [_vShow, !_unref(areFilesIds) &&
              !_unref(operationIsExecuting) &&
              _unref(currentLocation) !== '/watermark']
            ]),
            _createVNode(OptionsAfterConvert, { onReset: reset }),
            _createVNode(OptionsAfterCount, { onReset: reset }),
            _createVNode(OptionsAfterWatermark, { onReset: reset })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[3] || (_cache[3] = _createElementVNode("h3", { id: "file-header" }, "File", -1)),
            _createVNode(TotalCountResults),
            (_unref(showLanguageComponent))
              ? (_openBlock(), _createElementBlock("h3", _hoisted_8, " Language "))
              : _createCommentVNode("", true)
          ]),
          (_unref(areAllFilesCounted) && areOperationsFailed())
            ? (_openBlock(), _createBlock(WarningMessage, {
                key: 0,
                fileWarningMargins: true,
                message: "Some of your files couldn't be processed. Please try using them in a new submission."
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["file-box", {
            'scrollable': isScrollable.value,
            'decrease-height': showDropbox.value,
            'watermark-height' : applyWatermarkHeight.value,
            'low-res-height': applyLowResHeight.value,
          }]),
            ref_key: "fileBox",
            ref: fileBox
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files.value, (file, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "column"
              }, [
                _createVNode(FileDetails, {
                  index: index,
                  onRemoveFile: _cache[2] || (_cache[2] = ($event: any) => (removeFileFromPage($event)))
                }, null, 8, ["index"])
              ]))
            }), 128))
          ], 2)
        ], 512), [
          [_vShow, _unref(areFilesUploaded)]
        ])
      ]),
      _createElementVNode("section", _hoisted_9, [
        _createVNode(UserFileHistory, {
          ref_key: "userFileHistoryRef",
          ref: userFileHistoryRef,
          onRemoveFileCalledFromHistory: removeFileCalledFromHistory,
          onRemoveAllFilesCalledFromHistory: removeAllFilesCalledFromHistory,
          onReUse: reUseFile
        }, null, 512)
      ]),
      _createVNode(FeedbackSurveyLink)
    ])
  ], 64))
}
}

})