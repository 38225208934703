import { analyticsService } from '@/services/analyticsService';
import { HistoryTracker } from './historyTracker';
import { ConvertTracker } from './convertTracker';
import { CountTracker } from './countTracker';
import { WatermarkTracker } from './watermarkTracker';

export const historyTracker = new HistoryTracker(analyticsService);
export const convertTracker = new ConvertTracker(analyticsService);
export const countTracker = new CountTracker(analyticsService);
export const watermarkTracker = new WatermarkTracker(analyticsService);
