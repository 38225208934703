import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "watermark-settings-container" }
const _hoisted_2 = { class: "settings-section" }
const _hoisted_3 = { class: "settings-column" }
const _hoisted_4 = { for: "watermark-text" }
const _hoisted_5 = { class: "validation-error-text" }
const _hoisted_6 = { class: "color-input-container" }
const _hoisted_7 = { class: "validation-error-text" }
const _hoisted_8 = { class: "settings-column" }
const _hoisted_9 = { class: "action-buttons-container" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["disabled"]

import {
  ref,
  computed,
} from "vue";
import { useStore } from "vuex"
import WatermarkPreview from "@/components/WatermarkSettings/WatermarkPreview.vue";
import addWatermarks from "./addWatermarks"
import { analytics } from '@/utils/analitycs';
import formatFileSize from "@/services/formatFileSize";
import { mapGetters } from "@/store/utils/mapGetters";
import WatermarkSettings from '@/interfaces/watermarkSettings';
import File from "@/interfaces/File";


export default /*@__PURE__*/_defineComponent({
  __name: 'WatermarkSettings',
  emits: ["reset"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const store = useStore();
const oidcUser = computed(() => store.getters.oidcUser);
const watermarkText = ref(oidcUser.value.sub);
const watermarkColor = ref("#c1bebe");
const watermarkColorTransparency = ref(50);
const isHexColorInvalid = ref(false);
const watermarkScale = ref(1);
const watermarkDensity = ref(30);
const watermarkAngle = ref(-45);
const { operationIsExecuting, operationFailedRetryCount } = mapGetters();

const watermarkColorRgba = computed(() => watermarkColor.value + hexToAlphaHex(watermarkColorTransparency.value));
const watermarkColorArgb = computed(() => '#' + hexToAlphaHex(watermarkColorTransparency.value) + watermarkColor.value.substring(1));
const areInputsValid = computed(() => !isHexColorInvalid.value && watermarkText.value.trim() !== '');

function startNewWatermark() {
  const watermarkSettings: WatermarkSettings = {
    text: watermarkText.value,
    color: watermarkColorArgb.value,
    fontFamily: 'Arial',
    fontSize: 16 * watermarkScale.value,
    angle: watermarkAngle.value,
    distance: watermarkDensity.value * watermarkScale.value,
  }
  addWatermarks(watermarkSettings);
  analytics.watermarkTracker.trackWatermarkApply();
}

function validateHexColor() {
  const hexRegex = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;
  isHexColorInvalid.value = !hexRegex.test(watermarkColor.value);
}

function hexToAlphaHex(transparency: number) {
  return Math.round(transparency / 100 * 255).toString(16).padStart(2, '0');
}
function addMoreFiles(event) {
  const files:File[] = Array.from(event.target.files);
  files.forEach(file => {
    store.commit("addUploadedFiles", {
      name: file.name,
      id: "",
      language: [],
      languageCode: [],
      nrWords: "",
      nrPages: "",
      currentFileFormat: 'pdf',
      fileSize: formatFileSize(file.size),
      downloadLink: "",
      convertRetryCount: operationFailedRetryCount.value,
      pagesRetryCount: operationFailedRetryCount.value,
      wordsRetryCount: operationFailedRetryCount.value,
      uploadProgress: 0,
      operationProgress: { progress: 0, status: '' },
      duplicatedFileName: false
    });
  });
  emit('add-more-files', files);
  analytics.watermarkTracker.trackAddFiles();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[10] || (_cache[10] = _createElementVNode("h1", { class: "settings-title" }, "Settings", -1)),
        _createElementVNode("label", _hoisted_4, [
          _cache[9] || (_cache[9] = _createTextVNode(" Enter your text: ")),
          _withDirectives(_createElementVNode("span", _hoisted_5, " Input cannot be empty ", 512), [
            [_vShow, !watermarkText.value]
          ])
        ]),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((watermarkText).value = $event)),
          type: "text",
          id: "watermark-text",
          placeholder: "Enter text"
        }, null, 512), [
          [_vModelText, watermarkText.value]
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("label", { for: "watermark-color-picker" }, "Choose the text color:", -1)),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((watermarkColor).value = $event)),
            type: "text",
            id: "watermark-color-code",
            onInput: validateHexColor
          }, null, 544), [
            [_vModelText, watermarkColor.value]
          ]),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((watermarkColor).value = $event)),
            type: "color",
            id: "watermark-color-picker",
            onInput: validateHexColor
          }, null, 544), [
            [_vModelText, watermarkColor.value]
          ]),
          _withDirectives(_createElementVNode("span", _hoisted_7, " Invalid color code ", 512), [
            [_vShow, isHexColorInvalid.value]
          ])
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("label", { for: "watermark-scale" }, "Choose the text scale:", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((watermarkScale).value = $event)),
          type: "range",
          id: "watermark-scale",
          min: "1",
          max: "4"
        }, null, 512), [
          [_vModelText, watermarkScale.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[13] || (_cache[13] = _createElementVNode("label", { for: "watermark-transparency" }, "Choose the text transparency:", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((watermarkColorTransparency).value = $event)),
          type: "range",
          id: "watermark-transparency",
          min: "10",
          max: "100",
          step: "9"
        }, null, 512), [
          [_vModelText, watermarkColorTransparency.value]
        ]),
        _cache[14] || (_cache[14] = _createElementVNode("label", { for: "watermark-density" }, "Choose the text density:", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((watermarkDensity).value = $event)),
          type: "range",
          id: "watermark-density",
          min: "20",
          max: "80",
          step: "10"
        }, null, 512), [
          [_vModelText, watermarkDensity.value]
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("label", { for: "watermark-angle" }, "Choose the text orientation:", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((watermarkAngle).value = $event)),
          type: "range",
          id: "watermark-angle",
          min: "-180",
          max: "180",
          step: "45"
        }, null, 512), [
          [_vModelText, watermarkAngle.value]
        ])
      ]),
      _createVNode(WatermarkPreview, {
        text: watermarkText.value,
        color: watermarkColorRgba.value,
        scale: watermarkScale.value / 1.5,
        density: Math.floor(watermarkDensity.value),
        angle: watermarkAngle.value
      }, null, 8, ["text", "color", "scale", "density", "angle"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("label", {
        class: _normalizeClass(["outline-button", { 'disabled': _unref(operationIsExecuting) }])
      }, [
        _cache[16] || (_cache[16] = _createTextVNode(" +  Add more files ")),
        _createElementVNode("input", {
          type: "file",
          multiple: "",
          accept: "application/pdf",
          style: {"display":"none"},
          onChange: addMoreFiles
        }, null, 32)
      ], 2),
      _createElementVNode("button", {
        class: "outline-button",
        disabled: _unref(operationIsExecuting),
        onClick: _cache[7] || (_cache[7] = ($event: any) => (emit('reset'),  _unref(analytics).watermarkTracker.trackWatermarkCancel()))
      }, " Cancel ", 8, _hoisted_10),
      _createElementVNode("button", {
        class: "custom-blue-button",
        disabled: _unref(operationIsExecuting) || !areInputsValid.value,
        onClick: _cache[8] || (_cache[8] = ($event: any) => (startNewWatermark()))
      }, " Apply ", 8, _hoisted_11)
    ])
  ]))
}
}

})