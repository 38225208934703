import store from '@/store/store';
import applyWatermark from "@/services/watermarks/applyWatermark";
import checkOperation from "@/services/checkOperation";
import WatermarkSettings from '@/interfaces/watermarkSettings';
import File from '@/interfaces/File';
import FAILED from "@/constants/failed";

let isFileWatermarked: Array<boolean> = [];

export default async function addWatermarks(watermarkSettings: WatermarkSettings) {
  const fileOperationMap = await applyWatermark(store.getters.uploadedFilesIds, watermarkSettings);
  const operationsIds = Object.values<string>(fileOperationMap);
  const filesIds = Object.keys(fileOperationMap);
  store.commit("setIsWatermarkOperationStarted", true);
  store.commit("setOperationIsExecuting", true);
  operationsIds.forEach(async (operationId: string, index: number) => {
    store.commit("setUploadedFiles", {
      payload: { progress: 0, status:'Pending'},
      prop: "operationProgress",
      index,
    });
    checkWatermarkStatus(operationId, filesIds[index]);
    isFileWatermarked.push(false);
  });
}

async function checkWatermarkStatus(operationId: string, fileId: string) {
  const index = store.getters.uploadedFiles.findIndex((file: File) => file.id === fileId);
  const operationCall = setInterval(async () => {
    const { status, progress } = await checkOperation(operationId);
    store.commit("setUploadedFiles", {
      payload: { progress: progress ?? 0, status:status},
      prop: "operationProgress",
      index,
    });
    if (status === "Completed") {
      store.commit("setUploadedFiles", {
        payload: { progress: 100, status: status},
        prop: "operationProgress",
        index,
      });
      clearInterval(operationCall);
      isFileWatermarked[index] = true;
      areAllFilesWatermarked();
    } else if (status === FAILED) {
      store.commit("setUploadedFiles", { payload: FAILED, prop: 'downloadLink', index });
      clearInterval(operationCall);
      isFileWatermarked[index] = true;
      areAllFilesWatermarked();
    }
  }, 1000);
}

function areAllFilesWatermarked() {
  if (isFileWatermarked.every(element => element === true)) {
    store.commit("setOperationIsExecuting", false);
    isFileWatermarked = [];
  }
}
