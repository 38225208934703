import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "feedback-container" }
const _hoisted_2 = { class: "feedback-tooltip" }

import NewTabIcon from "@/components/icons/NewTabIcon.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'FeedbackSurveyLink',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("a", {
      href: "https://a2a-internal-feedback.ideas.aha.io/ideas/new",
      target: "_blank",
      class: "feedback-button"
    }, " Help us improve ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(NewTabIcon, {
        width: "1rem",
        height: "1rem"
      }),
      _cache[0] || (_cache[0] = _createTextVNode(" Open survey in a new tab "))
    ])
  ]))
}
}

})