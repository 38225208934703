import MatomoCategory from '@/enums/matomo/MatomoCategory';
import MatomoCount from '@/enums/matomo/MatomoCount';
import { AnalyticsService } from '@/services/analyticsService';

export class CountTracker {
  private analytics: AnalyticsService;

  constructor(analytics: AnalyticsService) {
    this.analytics = analytics;
  }

  private track(action: string, event?: string): void {
    this.analytics.trackEvent({
      category: MatomoCategory.COUNT,
      action,
      event,
    });
  }

  public trackCount() {
    this.track(MatomoCount.COUNT);
  }

  public trackRemoveFile() {
    this.track(MatomoCount.REMOVE_FILE);
  }

  public trackStartOver() {
    this.track(MatomoCount.START_OVER);
  }

  public trackUploadLink() {
    this.track(MatomoCount.UPLOAD_LINK);
  }
}
