enum MatomoHistory {
  SEARCH = 'Search activation',
  CLEAR_HISTORY = 'Click Clear History',
  PREVIEW = 'Click Preview',
  REUSE = 'Click Reuse file',
  DELETE_FILE = 'Click Delete file',
  DOWNLOAD_AS= 'Click Download as',
}

export default MatomoHistory;
