import axios from 'axios';
import store from '../store/store';

export default async function getLanguages() {
  await axios
    .options(`${process.env.VUE_APP_BASE_URL}/api/files`)
    .then((response) => {
      store.commit("setLanguageList", response.data.language);
    })
    .catch((err) => console.error(err));
}
