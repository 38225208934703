import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "operations-result-container"
}
const _hoisted_2 = ["disabled"]

import RetryIcon from "@/components/icons/RetryIcon.vue";
import { mapGetters } from "@/store/utils/mapGetters";
import { analytics } from '@/utils/analitycs';


export default /*@__PURE__*/_defineComponent({
  __name: 'OptionsAfterCount',
  emits: ["reset"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const { areAllFilesCounted, operationIsExecuting } = mapGetters();

return (_ctx: any,_cache: any) => {
  return (_unref(areAllFilesCounted))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          id: "start-over-button-count",
          class: "custom-blue-button",
          disabled: _unref(operationIsExecuting),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('reset'), _unref(analytics).countTracker.trackStartOver()))
        }, [
          _createVNode(RetryIcon, {
            width: "0.75rem",
            height: "0.75rem"
          }),
          _cache[1] || (_cache[1] = _createTextVNode(" Start over "))
        ], 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}
}

})