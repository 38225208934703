import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-row-history" }
const _hoisted_2 = { class: "file-name-box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "file-details" }
const _hoisted_5 = { class: "file-name" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  id: "file-exp-date",
  class: "info"
}

import ExpandArrow from "@/components/icons/ExpandArrow.vue";
import FileFromHistory from "@/interfaces/FileFromHistory";


export default /*@__PURE__*/_defineComponent({
  __name: 'HistoryFile',
  props: {
    file: {},
    index: {}
  },
  emits: ["update-file-count-results", "update-history-box-height"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

function toggle(file: FileFromHistory) {
  file.showHiddenFileDetails = !file.showHiddenFileDetails;
  emit("update-file-count-results", file.id);
  emit("update-history-box-height");
}
function getImgUrl(file: FileFromHistory) {
  const formatPattern = /[0-9a-z]+$/i;
  const fileFormat: RegExpMatchArray = file.name.match(formatPattern) as RegExpMatchArray ;
  return require(`@/svg/format_icons/${fileFormat[0].toUpperCase()}.svg`);
}

function calculateExpirationDate(fileExpirationDate: Date) {
  const dateNow: Date = new Date();
  const fileExpDate: Date = new Date(fileExpirationDate);
  const timeDifference: number = Math.abs(+fileExpDate - +dateNow);
  const differenceInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor(timeDifference / (1000 * 60 * 60));
  const differenceInMinutes = Math.floor(timeDifference / (1000 * 60));

  if (differenceInMinutes <= 1) {
    return "1 minute";
  } else if (differenceInHours < 1) {
    return `${differenceInMinutes} minutes`;
  } else if (differenceInHours === 1) {
    return `1 hour`;
  } else if (differenceInHours < 24) {
    return `${differenceInHours} hours`;
  } else if (differenceInDays === 1) {
    return `1 day`;
  }
  return `${differenceInDays} days`;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(["file-name-tooltip", { 'first-file-name-tooltip': _ctx.index === 0 }])
      }, _toDisplayString(props.file.name), 3),
      _createElementVNode("img", {
        src: getImgUrl(_ctx.file),
        class: "format-icon",
        alt: "File icon with text in the center, representing the file extension",
        draggable: "false"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.file.name), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, " Expires in " + _toDisplayString(calculateExpirationDate(_ctx.file.expirationDate)), 1)
        ])
      ])
    ]),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (toggle(_ctx.file)))
    }, [
      _createVNode(ExpandArrow, {
        class: _normalizeClass(["expand-arrow icon-color", { 'arrow-rotate': _ctx.file.showHiddenFileDetails }])
      }, null, 8, ["class"])
    ])
  ]))
}
}

})