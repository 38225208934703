// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { saveAs } from 'file-saver';
import axios from 'axios';
import JsZip from 'jszip';
import store from '@/store/store';

export default async function downloadWatermarkZipFiles() {
  store.commit("setFilesAreDownloading", true);
  const zip = new JsZip();
  const { uploadedFiles } = store.getters;
  for (const uploadedFile of uploadedFiles) {
    await axios({
      method: 'GET',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${uploadedFile.id}/watermarked`,
      responseType: 'blob',
    })
      .then((response) => (zip.file(uploadedFile.name, response.data)))
      .catch(console.error);
  }
  zip.generateAsync({ type: 'blob' }).then((zip) => {
    saveAs(zip, 'allFiles.zip');
    store.commit("setFilesAreDownloading", false);
  });
}