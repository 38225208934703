import store from "@/store/store";

export default function setProcessButtonText() {
  const button = document.querySelector("#process-button");
  if (button !== null && store.getters.currentLocation === "/convert") {
    return "Next";
  } else if (button !== null && store.getters.currentLocation === "/count") {
    button.removeAttribute("id");
    button.setAttribute("id", "countButton");
  }
  return "Count";
}
