import { createStore } from 'vuex';
import { VuexOidcClientSettings, vuexOidcCreateStoreModule } from 'vuex-oidc';
import { oidcSettings } from '../components/auth/oidcSettings';
import { mutations } from './mutations';
import { state } from './state';
import { getters } from './getters';

const store = createStore({
  state,
  mutations,
  getters,
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings as VuexOidcClientSettings,
    ),
  },
});
export default store;