import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["data-value", "data-list"]
const _hoisted_2 = ["placeholder", "disabled"]
const _hoisted_3 = {
  id: "formats-dropdown",
  class: "selector"
}
const _hoisted_4 = { id: "format-list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { id: "loading-formats" }
const _hoisted_7 = { class: "small-spinner outside-spinner" }

import {
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  watch,
} from "vue";
import ArrowIcon from "@/components/icons/DropdownArrowIcon.vue";
import { useStore } from "vuex";
import { mapGetters } from "@/store/utils/mapGetters";
import getFormats from "@/services/getFormats";

enum PlaceholderValues {
  ConvertTo = "Convert to",
  NoAvailableFormats = "No available formats"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormatSelect',
  setup(__props) {

const store = useStore();
const searchQuery = ref<string>('');
const selectedFormat = ref<string>('');
const inputPlaceholder = ref<PlaceholderValues>(PlaceholderValues.ConvertTo);
const isVisible = ref(false);
const formatDropdown = ref(null);
const formatInput = ref(null);
const showSpinner = ref(false);
const {
  areAvailableFormats,
  areAllFilesUploaded,
  availableFormats,
  showProgressBar,
  uploadedFilesIds,
  uploadedFiles,
} = mapGetters();

const filteredFormat = computed(() => {
  if (!searchQuery.value) {
    return availableFormats.value;
  }
  const query = searchQuery.value.toLocaleLowerCase();
  return availableFormats.value.filter((format: string) =>
    format.toLocaleLowerCase().startsWith(query)
  );
});

onMounted(() => document.addEventListener('click', closeOnOutsideClick));

onBeforeUnmount(() => document.removeEventListener('click', closeOnOutsideClick));

function selectFormat(format: string) {
  selectedFormat.value = format;
  searchQuery.value = format;
  store.commit("setSelectedFormat", format);
  isVisible.value = false;
}


function resetFormatValues () {
  selectedFormat.value = "";
  searchQuery.value = "";
  store.commit("setSelectedFormat", "");
  inputPlaceholder.value = PlaceholderValues.ConvertTo;
}

function toggleDropdown() {
  isVisible.value = !isVisible.value;
  formatInput.value.focus();
  if (!isVisible.value) {
    searchQuery.value = selectedFormat.value;
  } else {
    resetFormatValues();
  }
}

function closeOnOutsideClick(event) {
  if (isVisible.value && !formatDropdown.value.contains(event.target)) {
      isVisible.value = false;
      searchQuery.value = selectedFormat.value;
      store.commit("setSelectedFormat", selectedFormat.value);
    }
}

function setPredefinedFormats() {
  showSpinner.value = true;
  if(checkIfFilesAreSameFormat("pdf")) {
    store.commit("setAvailableFormats", ["DOCX", "DOC"]);
    selectFormat("DOCX");
  } else if (checkIfFilesAreSameFormat("docx") || checkIfFilesAreSameFormat("doc")) {
    store.commit("setAvailableFormats", ["PDF"]);
    selectFormat("PDF");
  } else {
    showSpinner.value = false;
    store.commit("setShowProgressBar", false);
  }
}

function checkIfFilesAreSameFormat(format: string): boolean {
  return uploadedFiles.value.every((file) => file.currentFileFormat === format)
}

watch(availableFormats, (newFormats) => {
  if(newFormats.length > 2 && areAllFilesUploaded.value) {
    store.commit("setShowProgressBar", true);
    inputPlaceholder.value = PlaceholderValues.ConvertTo;
    showSpinner.value = false;
  } else if (!availableFormats.value.length) {
    resetFormatValues();
  }
});

watch(() => areAllFilesUploaded.value, () => {
  getFormats(uploadedFilesIds.value);
  setPredefinedFormats();
});

watch(showProgressBar, newValue => newValue === false ? resetFormatValues() : null);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "formats-container",
    class: "aselect",
    ref_key: "formatDropdown",
    ref: formatDropdown,
    "data-value": selectedFormat.value,
    "data-list": _unref(availableFormats)
  }, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
      id: "format-input",
      ref_key: "formatInput",
      ref: formatInput,
      type: "text",
      placeholder: inputPlaceholder.value,
      disabled: !_unref(showProgressBar),
      onClick: toggleDropdown
    }, null, 8, _hoisted_2), [
      [_vModelText, searchQuery.value]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass({ hidden: !isVisible.value })
      }, [
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredFormat.value, (format) => {
            return (_openBlock(), _createElementBlock("li", {
              key: format,
              onClick: ($event: any) => (selectFormat(format))
            }, _toDisplayString(format), 9, _hoisted_5))
          }), 128)),
          _withDirectives(_createElementVNode("span", _hoisted_6, _cache[1] || (_cache[1] = [
            _createTextVNode(" Loading more...  "),
            _createElementVNode("span", { class: "small-spinner" }, null, -1)
          ]), 512), [
            [_vShow, showSpinner.value]
          ])
        ])
      ], 2)
    ]),
    _withDirectives(_createVNode(ArrowIcon, {
      class: _normalizeClass(["arrow icon-color", { 'arrow-rotate': isVisible.value }]),
      width: "1.625rem",
      height: "1.625rem",
      onClick: toggleDropdown
    }, null, 8, ["class"]), [
      [_vShow, _unref(showProgressBar)]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_7, null, 512), [
      [_vShow, !_unref(showProgressBar)]
    ])
  ], 8, _hoisted_1))
}
}

})