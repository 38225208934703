import type { AnalyticsService, EventPayload } from './analytics-service';

export class AnalyticsServiceImpl implements AnalyticsService {
  private queuedEvents: EventPayload[] = [];

  private flushQueuedEvents(): void {
    while (this.queuedEvents.length) {
      const event = this.queuedEvents.shift();

      if (event) {
        this.trackEvent(event);
      }
    }
  }

  trackEvent(event: EventPayload): void {
    const { _paq: matomo } = window;

    if (matomo) {
      this.flushQueuedEvents();

      matomo.push(['trackEvent', event.category, event.action, event.event, event.value]);
    } else {
      this.queuedEvents.push(event);
    }
  }
}
