import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { ref, computed } from "vue";
import { useStore } from "vuex"
import router from "@/router";
import {type GlobalLinkHeader as GlHeader,} from '@compass/header';
import AboutModal from "./AboutModal.vue";
import { mapGetters } from "@/store/utils/mapGetters";


export default /*@__PURE__*/_defineComponent({
  __name: 'TheNavBar',
  setup(__props) {

const store = useStore();
const { isDarkTheme } = mapGetters();
const root = ref(document.querySelector('html'));
const showAboutModal = ref(false);
const cookiesUrl = process.env.VUE_APP_COOKIE_SETTINGS_REDIRECT_URI;
const env = process.env.VUE_APP_ENV;
const user = computed(() => store.getters.oidcUser);
const accessToken = computed(() => store.getters.oidcAccessToken);
const theme = computed(() => isDarkTheme.value ? "dark" : "light");

function setTheme(event: CustomEvent): void {
  const theme = event.detail.theme;

  if ((theme === "dark" && isDarkTheme.value) || (theme === "light" && !isDarkTheme.value)) {
    return;
  }
  store.commit("setIsDarkTheme", theme === "dark");
  const newTheme = theme === "dark" ? "dark" : "light";
  const oldTheme = newTheme === "dark" ? "light" : "dark";

  root.value.classList.remove(oldTheme);
  root.value.classList.add(newTheme);
  window.localStorage.setItem('app-theme-key', newTheme);
}

function logout() {
  router.push("/logout");
  store.dispatch("signOutOidc");
}

return (_ctx: any,_cache: any) => {
  const _component_gl_header = _resolveComponent("gl-header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_gl_header, {
      id: "navigation",
      "app-name": "A2A",
      theme: theme.value,
      user: user.value,
      env: _unref(env),
      accessToken: accessToken.value,
      "cookie-settings-url": _unref(cookiesUrl),
      "disable-online-help": "",
      "disable-privacy-policy": "",
      "disable-security-settings": "",
      "disable-create-account": "",
      "disable-sign-in": "",
      "disable-theme-high-contrast": "",
      "disable-theme-system-default": "",
      "disable-navigation": "",
      "enable-test-ids": "",
      "disable-settings": "",
      "disable-user-guide": "",
      "send-feedback-url": "https://a2a-internal-feedback.ideas.aha.io/ideas/new",
      "privacy-policy-url": "https://www.transperfect.com/privacy/technology",
      "contact-support-url": "mailto:converterhelp@transperfect.com",
      onAbout: _cache[0] || (_cache[0] = ($event: any) => (showAboutModal.value = true)),
      onSetTheme: setTheme,
      onLogout: logout
    }, null, 8, ["theme", "user", "env", "accessToken", "cookie-settings-url"]),
    (showAboutModal.value)
      ? (_openBlock(), _createBlock(AboutModal, {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (showAboutModal.value = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})