import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownArrowIcon',
  props: ['width', 'height'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createElementBlock("svg", {
      width: __props.width,
      height: __props.height,
      viewBox: "0 0 24 24",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("path", {
        d: "M7 10L12 15L17 10H7Z",
        fill: "currentColor"
      }, null, -1),
      _createElementVNode("rect", {
        width: "24",
        height: "24"
      }, null, -1)
    ]), 8, _hoisted_1))
  ]))
}
}

})