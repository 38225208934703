import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "language-dropdown-popover" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "language-options" }
const _hoisted_4 = { id: "global-language-list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "langauge-pill" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "info" }

import {
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  watch,
  nextTick,
} from "vue";
import ArrowIcon from "@/components/icons/DropdownArrowIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import { useStore } from "vuex";
import { mapGetters } from "@/store/utils/mapGetters";
import Language from "@/interfaces/Language";
import ISO6391 from "iso-639-1";
import File from "@/interfaces/File";


export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalLanguageSelector',
  setup(__props) {

const store = useStore();
const searchQuery = ref('');
const showDropdown = ref(false);
const selectedLanguages = ref<Array<Language>>([]);
const languageNames = ref<Array<Language>>([]);
const removeLanguageButtons =  ref<Array<HTMLElement | null>>([]);
const globalLanguageSelector = ref<HTMLElement | null>(null);
const globalLanguageDropdown = ref<HTMLElement | null>(null);
const fileLimitReached = ref(false);
const {
  languageList,
  uploadedFiles,
  resetGlobalSelector,
} = mapGetters();

const placeholder = computed(() => !selectedLanguages.value.length ? "Add languages for all" : "");
const showLanguages = computed(() => !showDropdown.value && selectedLanguages.value.length);
const showSelector = computed(() => showDropdown.value || !selectedLanguages.value.length);
const filteredLanguage = computed(() => {
  if (!searchQuery.value) return languageNames.value;

  return languageNames.value.filter((language) =>
    language.fullName
      .toLocaleLowerCase()
      .startsWith(searchQuery.value.toLocaleLowerCase())
  );
});

function selectGlobalLanguages(language: Language) {
  if(resetGlobalSelector.value) resetAllLanguages();
  if (selectedLanguages.value.length < 3) {
    selectedLanguages.value.push(language);
    store.commit("setResetGlobalSelector", false);
    uploadedFiles.value.forEach((file: File, index: number) => {
      store.commit("addFileLanguage", {
        payload: language.fullName,
        index,
      });
      store.commit("addFileLanguageCode", {
        payload: language.shortName,
        index,
      });
    });
  }
}

async function toggleDropdown(event: MouseEvent) {
  if (removeLanguageButtons.value.some(button => button && button.contains(event.target as HTMLElement | null))) {
    return;
  } else if (selectedLanguages.value.length === 3 && !showDropdown.value) {
    fileLimitReached.value = true;
    return;
  }

  showDropdown.value = !showDropdown.value;
  await nextTick();
  if (showDropdown.value) {
    globalLanguageDropdown.value?.focus();
  } else {
    globalLanguageDropdown.value?.blur();
  }
  searchQuery.value = "";
}

function closeOnOutsideClick(event: MouseEvent) {
  const clickedOutside = !globalLanguageSelector.value?.contains(event.target as HTMLElement | null);

  if (showDropdown.value && clickedOutside) {
    showDropdown.value = false;
    searchQuery.value = "";
  }
  if(fileLimitReached.value && clickedOutside) fileLimitReached.value = false;
}

function removeLanguage(languageIndex: number) {
  selectedLanguages.value.splice(languageIndex, 1);
  fileLimitReached.value = false;
  uploadedFiles.value.forEach((file: File, i: number) => {
    store.commit("removeFileLanguage", {
      fileIndex: i,
      languageIndex,
    });
    store.commit("removeFileLanguageCode", {
      fileIndex: i,
      languageIndex,
    });
  });
}

function resetAllLanguages() {
  uploadedFiles.value.forEach((file: File, index: number) => {
    store.commit("setUploadedFiles", {
      payload: [],
      prop:"language",
      index,
    });
    store.commit("setUploadedFiles", {
      payload: [],
      prop:"languageCode",
      index,
    });
  });
}

watch(languageList, () => {
  languageList.value.forEach((lang: string, index: number) => {
    const language = ISO6391.getName(languageList.value[index]);
    if (language !== "") {
      languageNames.value.push({
        fullName: ISO6391.getName(languageList.value[index]),
        shortName: languageList.value[index],
      });
    }
  });
});

watch(
  () => store.state.resetGlobalSelector, (newVal) => {
    if (newVal === true) {
      selectedLanguages.value = [];
    }
  }
);

onMounted(() => {
  languageNames.value.sort((a, b) => a.fullName.localeCompare(b.fullName));
  document.addEventListener('click', closeOnOutsideClick);
});

onBeforeUnmount(() => document.removeEventListener('click', closeOnOutsideClick));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "global-language-wrapper",
    ref_key: "globalLanguageSelector",
    ref: globalLanguageSelector
  }, [
    _withDirectives(_createElementVNode("section", {
      class: "language-wrapper",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleDropdown($event)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
          id: "global-language-dropdown",
          ref_key: "globalLanguageDropdown",
          ref: globalLanguageDropdown,
          type: "text",
          placeholder: placeholder.value
        }, null, 8, _hoisted_2), [
          [_vModelText, searchQuery.value]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredLanguage.value, (language, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                onClick: ($event: any) => (selectGlobalLanguages(language))
              }, _toDisplayString(language.fullName), 9, _hoisted_5))
            }), 128))
          ])
        ], 512), [
          [_vShow, showDropdown.value]
        ])
      ])
    ], 512), [
      [_vShow, showSelector.value]
    ]),
    _withDirectives(_createElementVNode("section", {
      class: _normalizeClass(["selected-languages", {error:  fileLimitReached.value }]),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (toggleDropdown($event)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedLanguages.value, (language, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("span", _hoisted_6, [
            _createTextVNode(_toDisplayString(language.fullName) + " ", 1),
            _createElementVNode("button", {
              class: "remove-language-button",
              ref_for: true,
              ref_key: "removeLanguageButtons",
              ref: removeLanguageButtons,
              onClick: ($event: any) => (removeLanguage(index))
            }, [
              _createVNode(CloseIcon, {
                class: "icon-color",
                width: "1rem",
                height: "1rem"
              })
            ], 8, _hoisted_7)
          ])
        ]))
      }), 128)),
      _withDirectives(_createElementVNode("span", _hoisted_8, " You can only add up to 3 languages in each document ", 512), [
        [_vShow, fileLimitReached.value]
      ])
    ], 2), [
      [_vShow, showLanguages.value]
    ]),
    _createVNode(ArrowIcon, {
      class: _normalizeClass(["arrow icon-color", { 'arrow-rotate': showDropdown.value }]),
      width: "1.625rem",
      height: "1.625rem",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (toggleDropdown($event)))
    }, null, 8, ["class"])
  ], 512))
}
}

})