export default function mapFileTypeToEditor(format: string): string {
  const documentFormats = ["DOC", "DOCX", "DOT", "DOTX", "DOTM", "DOCM", "CSV", "HTM", "HTML", "MHT", "MHTML", "ODT", "OPC", "TSV", "TXT", "TXLF"];
  const presentationFormats = ["POTM", "POTX", "PPSM", "PPSX", "PPT", "PPTX", "PPTM", "ODP"];
  const spreadsheetFormats = ["XLS", "XLSX", "XML", "XPS", "XLSM", "NUMBERS", "ODS"];
  if (format === "PDF") {
    return "pdf";
  } else if (documentFormats.includes(format)) {
    return "docx";
  } else if (spreadsheetFormats.includes(format)) {
    return "xlsx";
  } else if (presentationFormats.includes(format)) {
    return "pptx";
  } else {
    return "pdf";
  }
}
