import axios from 'axios';
import store from '@/store/store';
import formatFileSize from './formatFileSize';
import File from '@/interfaces/File';

const convertApi = {
  async convertFiles(filesIds: Array<string>, format: string, disableOcr: boolean) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_BASE_URL}/api/operations/convert`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        files: filesIds,
        format,
        disableOcr
      },
    })
      .then((response) => response.data)
      .catch((err) => ({ error: true, data: err.response.data }));
  },
  async downloadFile(id: string, format: string) {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}?format=${format}`,
      headers: {
        'Content-Type': 'application/json',
      },
      responseEncoding: 'binary',
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const index = store.getters.uploadedFiles.findIndex((file: File) => file.id === id);
        const blob = new Blob([response.data]);
        const blobUrl = URL.createObjectURL(blob);
        this.createAndDownloadFileLink(blobUrl, index);
      })
      .catch((err) => console.error(err));
  },
  async getFileSize(id: string, format: string) {
    return axios({
      method: 'HEAD',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}?format=${format}`,
    })
      .then(response => {
        const index = store.getters.uploadedFiles.findIndex((file: File) => file.id === id);
        const byteSize = parseInt(response.headers['content-length']);
        const fileSize = formatFileSize(byteSize);
        store.commit("setUploadedFiles", {
          payload: fileSize,
          prop: "fileSize",
          index: index,
        });
      })
      .catch((err) => console.error(err));
  },
  async checkFileWasConverted(id: string, format: string): Promise<number> {
    return axios({
      method: 'HEAD',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}/?format=${format}`,
    })
    .then(async(response) => {
      if (response.status === 202) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return this.checkFileWasConverted(id, format);
      }
      return response.status;
    })
    .catch((error) => error.response.status);
  },
  createAndDownloadFileLink(blobUrl: string, index: number) {
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = store.getters.uploadedFiles[index].name;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  },
};
export default convertApi;
