import axios from 'axios';

export default async function deleteFile(id: string) {
  await axios({
    method: 'DELETE',
    url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}?related=true`,
  })
    .then(() => {return})
    .catch((err) => console.error(err));
}
