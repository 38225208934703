import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import MainContainer from "@/components/MainContainer/MainContainer.vue";
import NavBar from "@/components/TheNavBar/TheNavBar.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'WatermarkView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(NavBar),
    _createVNode(MainContainer)
  ], 64))
}
}

})