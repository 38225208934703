import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "file-info" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "file-name-box" }
const _hoisted_5 = { class: "file-name-tooltip" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  id: "upload-file-language",
  class: "info"
}
const _hoisted_8 = {
  id: "file-size",
  class: "info"
}
const _hoisted_9 = {
  key: 0,
  class: "info-warning"
}
const _hoisted_10 = { class: "file-actions" }
const _hoisted_11 = {
  key: 0,
  class: "operation-status-container"
}
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "info" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "operation-status-container" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "info" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "icons-box" }
const _hoisted_20 = {
  key: 0,
  class: "tooltip"
}

import { computed } from "vue";
import { mapGetters } from "@/store/utils/mapGetters";
import ConvertResults from "@/components/OperationResults/ConvertResults.vue";
import WatermarkResults from "@/components/OperationResults/WatermarkResults.vue";
import CountResults from "@/components/OperationResults/CountResults.vue";
import LanguageSelect from "@/components/LanguageSelect/LanguageSelector.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import FAILED from "@/constants/failed";


export default /*@__PURE__*/_defineComponent({
  __name: 'FileComponent',
  props: {
    index: {}
  },
  emits: ["remove-file"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const {
  operationIsExecuting,
  uploadedFiles,
  filePagesCounted,
  fileWordsCounted,
  currentLocation,
  fileWasConverted,
  showProgressBar,
  areAllFilesUploaded,
  showLanguageComponent
} = mapGetters();

const fileHasLanguageSelected = computed(() =>
  uploadedFiles.value[props.index].language.length > 0
);

function displayProgressBar(index: number) {
  return (
    currentLocation.value === "/count" &&
    operationIsExecuting.value &&
    !filePagesCounted.value[index] &&
    !fileWordsCounted.value[index] &&
    areAllFilesUploaded.value
  ) || (
    currentLocation.value === "/convert" &&
    operationIsExecuting.value &&
    !fileWasConverted.value[index] &&
    showProgressBar.value &&
    areAllFilesUploaded.value
  ) || (
    currentLocation.value === "/watermark" &&
    operationIsExecuting.value &&
    areAllFilesUploaded.value
  )
}

function getFileIconUrl(index: number) {
  const format = uploadedFiles.value[index].currentFileFormat.toUpperCase();
  return require(`@/svg/format_icons/${format}.svg`);
}

function fileHasFailedCount(index: number): boolean {
  return uploadedFiles.value[index].nrPages === FAILED ||
    uploadedFiles.value[index].nrWords === FAILED
    ? true
    : false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["file-row", { 'red-border': fileHasFailedCount(_ctx.index) && !_unref(operationIsExecuting) }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: getFileIconUrl(_ctx.index),
        class: "format-icon",
        alt: "Icon representing the file format type",
        draggable: "false"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, [
          _createTextVNode(_toDisplayString(_unref(uploadedFiles)[_ctx.index].name) + " ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(uploadedFiles)[_ctx.index].name), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("p", _hoisted_7, [
            _createTextVNode(_toDisplayString(_unref(uploadedFiles)[_ctx.index].language.join(", ")) + " ", 1),
            _cache[1] || (_cache[1] = _createElementVNode("span", null, "|  ", -1))
          ], 512), [
            [_vShow, fileHasLanguageSelected.value]
          ]),
          _createElementVNode("p", _hoisted_8, " Size " + _toDisplayString(_unref(uploadedFiles)[_ctx.index].fileSize), 1),
          (_unref(uploadedFiles)[_ctx.index].duplicatedFileName)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, " Detected as duplicate "))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      (
          _unref(operationIsExecuting) &&
          !_unref(areAllFilesUploaded) &&
          !_unref(uploadedFiles)[_ctx.index].id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("progress", {
              value: _unref(uploadedFiles)[_ctx.index].uploadProgress,
              max: "100"
            }, null, 8, _hoisted_12),
            _createElementVNode("p", _hoisted_13, " Uploaded " + _toDisplayString(_unref(uploadedFiles)[_ctx.index].uploadProgress) + "% of " + _toDisplayString(_unref(uploadedFiles)[_ctx.index].fileSize), 1)
          ]))
        : (
        _unref(operationIsExecuting) &&
        !_unref(areAllFilesUploaded) &&
        _unref(uploadedFiles)[_ctx.index].id)
          ? (_openBlock(), _createElementBlock("p", _hoisted_14, " Upload successful "))
          : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_15, [
        _createElementVNode("progress", {
          id: "transition-delay",
          value: _unref(uploadedFiles)[_ctx.index].operationProgress.progress,
          max: "100"
        }, null, 8, _hoisted_16),
        _createElementVNode("p", _hoisted_17, [
          _createTextVNode(_toDisplayString(_unref(uploadedFiles)[_ctx.index].operationProgress.status) + " ", 1),
          (_unref(uploadedFiles)[_ctx.index].operationProgress.status === 'Processing')
            ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_unref(uploadedFiles)[_ctx.index].operationProgress.progress) + "% ", 1))
            : _createCommentVNode("", true)
        ])
      ], 512), [
        [_vShow, displayProgressBar(_ctx.index)]
      ]),
      _createVNode(CountResults, { index: _ctx.index }, null, 8, ["index"]),
      _createElementVNode("div", _hoisted_19, [
        _withDirectives(_createVNode(LanguageSelect, { index: _ctx.index }, null, 8, ["index"]), [
          [_vShow, _unref(showLanguageComponent)]
        ]),
        _createVNode(ConvertResults, { index: _ctx.index }, null, 8, ["index"]),
        _createVNode(WatermarkResults, { index: _ctx.index }, null, 8, ["index"]),
        (!_unref(operationIsExecuting))
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _createElementVNode("span", {
                class: _normalizeClass(["tooltip-text", { 'first-element': _ctx.index === 0 }])
              }, " Remove file ", 2),
              _createElementVNode("button", {
                id: "remove-file-button",
                alt: "Remove File",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove-file', _ctx.index)))
              }, [
                _createVNode(CloseIcon, {
                  class: "icon-color",
                  width: "1.5rem",
                  height: "1.5rem"
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}
}

})