import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-content" }

import { onMounted, ref } from "vue";
import getApiVersion from "./utils/getApiVersion";


export default /*@__PURE__*/_defineComponent({
  __name: 'AboutModal',
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const apiVersion = ref("");

onMounted(async() => {
  apiVersion.value = await getApiVersion();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: "app-info-modal-background",
      class: "modal",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "close",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('close')))
      }, " × "),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, " Anything 2 Anything (A2A) is a tool for analyzing and converting files, primarily used for document interaction and optical character recognition (OCR) ", -1)),
      _createElementVNode("p", null, "Version: 2.11.0 | API " + _toDisplayString(apiVersion.value), 1)
    ])
  ], 64))
}
}

})