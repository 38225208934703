import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "preview-layer" }
const _hoisted_3 = ["width", "height"]

import {
  onMounted,
  ref,
  watch,
} from 'vue';
import { useStore } from "vuex"
import { mapGetters } from "@/store/utils/mapGetters";
import { drawWatermarkPreview } from '@/services/watermarks/drawWatermarkPreview';
import PreviewBackground from './svg/PreviewBackground.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'WatermarkPreview',
  props: {
    text: {},
    color: {},
    scale: {},
    density: {},
    angle: {}
  },
  setup(__props: any) {

const props = __props;

const store = useStore();
const previewCanvas = ref<HTMLCanvasElement>({} as HTMLCanvasElement);
const height = ref();
const width = ref();
const { isDarkTheme } = mapGetters();

function drawPreview() {
  drawWatermarkPreview(
    previewCanvas.value,
    props.text,
    props.color,
    props.scale,
    props.density,
    props.angle,
  );
}

function resizePreview() {
  if(window.innerWidth <= 1800) {
    height.value = 360;
    width.value = 252;
  } else {
    height.value = 450;
    width.value = 315;
  }
  requestAnimationFrame(drawPreview);
}

watch(() => [
  props.text,
  props.color,
  props.scale,
  props.density,
  props.angle,
], drawPreview);

onMounted(() => {
  resizePreview();
  window.addEventListener('resize', resizePreview);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", {
        width: width.value,
        height: height.value,
        class: "watermark-preview-canvas",
        ref_key: "previewCanvas",
        ref: previewCanvas
      }, null, 8, _hoisted_3),
      _createVNode(PreviewBackground, {
        class: "preview-background",
        width: width.value,
        height: height.value,
        color: _unref(isDarkTheme) ? '#f6f7f9' : '#43525C'
      }, null, 8, ["width", "height", "color"])
    ])
  ]))
}
}

})