import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "icons-box" }
const _hoisted_2 = { class: "small-spinner" }
const _hoisted_3 = { class: "tooltip" }
const _hoisted_4 = { class: "loading-message" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "tooltip" }
const _hoisted_7 = { class: "tooltip" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "tooltip" }
const _hoisted_10 = ["disabled"]

import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import FileViewer from "@/components/FileViewer/FileViewer.vue";
import OnlyOffice from "@/components/FileViewer/OnlyOffice/OnlyOffice.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import ReuseIcon from "@/components/icons/ReuseIcon.vue";
import DownloadIcon from "@/components/icons/DownloadIcon.vue";
import { mapGetters } from "@/store/utils/mapGetters";
import { analytics } from '@/utils/analitycs';
import FileFromHistory from "@/interfaces/FileFromHistory";
import historyApi from "@/services/historyApi";
import ISO6391 from "iso-639-1";


export default /*@__PURE__*/_defineComponent({
  __name: 'FileActions',
  props: {
    file: {},
    index: {},
    userHistoryLength: {},
    isHistoryScrollable: { type: Boolean }
  },
  emits: ["re-use", "delete-file"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const store = useStore();
const showFormatsSpinner = ref<boolean>(false);
const availableFormats = ref<Array<string>>([]);
const downloadButton = ref(null);
const historyModal = ref(null);
const isFileDownloading = ref(false);
const {
  operationIsExecuting,
  uploadedFiles,
  uploadedFilesIds,
  showLanguageComponent,
  areAllFilesConverted,
  currentLocation,
  toggleHistoryModal,
  showHistoryModal,
  isWatermarkOperationStarted
} = mapGetters();

const isReuseAvailable = computed(() => !((
    uploadedFiles.value.length
    && showLanguageComponent.value
    || areAllFilesConverted.value
    || operationIsExecuting.value
  ) || (
    currentLocation.value === '/watermark'
    && props.file.fileFormat !== 'pdf'
    || isWatermarkOperationStarted.value
  ))
);

onMounted(() => {
  document.addEventListener('click', closeOnOutsideClick);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', closeOnOutsideClick);
});

async function getFileAvailableFormats(file: FileFromHistory, index: number) {
  toggleHistoryModal.value(index);
  showFormatsSpinner.value = true;
  availableFormats.value = await historyApi.getAvailableFormatsForFile(file.id);
  showFormatsSpinner.value = false;
}

async function downloadFile(file: FileFromHistory, format: string) {
  isFileDownloading.value = true;
  analytics.historyTracker.trackDownloadFileAs(format);
  store.commit("setShowHistoryModal", {
    payload: false,
    index: props.index,
  });
  await historyApi.downloadFileFromHistory(file, format);
  isFileDownloading.value = false;
}

function isFileProcessing(id: string) {
  return uploadedFilesIds.value.includes(id);
}

function getLanguage(language: string) {
  analytics.historyTracker.trackReuse();
  return ISO6391.getName(language);
}

function closeOnOutsideClick(event: MouseEvent) {
  if (
    showHistoryModal.value[props.index] &&
    !downloadButton.value.contains(event.target) &&
    !historyModal.value.contains(event.target)
  ) {
    store.commit("setShowHistoryModal", {
      payload: false,
      index: props.index,
    });
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
      [_vShow, isFileDownloading.value]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _cache[3] || (_cache[3] = _createElementVNode("span", {
        id: "blue-tooltip",
        class: "tooltip-text"
      }, "Download", -1)),
      _createElementVNode("button", {
        id: "download-button",
        ref_key: "downloadButton",
        ref: downloadButton,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (getFileAvailableFormats(_ctx.file, _ctx.index)))
      }, [
        _createVNode(DownloadIcon, {
          class: "icon-color",
          width: "1.5rem",
          height: "1.5rem"
        })
      ], 512)
    ], 512), [
      [_vShow, !isFileDownloading.value]
    ]),
    _withDirectives(_createElementVNode("div", {
      ref_key: "historyModal",
      ref: historyModal,
      class: _normalizeClass(["history-modal", {
        reverse: _ctx.isHistoryScrollable && _ctx.index >= _ctx.userHistoryLength - 2,
      }])
    }, [
      _withDirectives(_createElementVNode("div", _hoisted_4, _cache[4] || (_cache[4] = [
        _createElementVNode("div", { class: "loading-spinner" }, null, -1),
        _createElementVNode("p", null, "please wait...", -1)
      ]), 512), [
        [_vShow, showFormatsSpinner.value]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "download-header" }, "Download as:", -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["available-formats-container", { scrollable: availableFormats.value.length > 4 }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableFormats.value, (format, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: index,
              class: "history-download-button",
              onClick: ($event: any) => (downloadFile(_ctx.file, format))
            }, _toDisplayString(format), 9, _hoisted_5))
          }), 128))
        ], 2)
      ], 512), [
        [_vShow, !showFormatsSpinner.value]
      ])
    ], 2), [
      [_vShow, _unref(showHistoryModal)[_ctx.index]]
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[6] || (_cache[6] = _createElementVNode("span", {
        id: "blue-tooltip",
        class: "tooltip-text"
      }, "Preview", -1)),
      _createVNode(FileViewer, {
        "file-name": _ctx.file.name,
        index: _ctx.index,
        useIconButton: true
      }, {
        default: _withCtx(() => [
          _createVNode(OnlyOffice, {
            fileFromHistory: _ctx.file,
            index: _ctx.index
          }, null, 8, ["fileFromHistory", "index"])
        ]),
        _: 1
      }, 8, ["file-name", "index"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[7] || (_cache[7] = _createElementVNode("span", {
        id: "blue-tooltip",
        class: "tooltip-text"
      }, "Reuse file", -1)),
      _createElementVNode("button", {
        id: "reuse-file-icon",
        disabled: !isReuseAvailable.value,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('re-use', _ctx.file, getLanguage(_ctx.file.languages[0]))))
      }, [
        _createVNode(ReuseIcon, {
          class: "icon-color",
          width: "1.5rem",
          height: "1.5rem"
        })
      ], 8, _hoisted_8)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _cache[8] || (_cache[8] = _createElementVNode("span", { class: "tooltip-text" }, "Delete file", -1)),
      _createElementVNode("button", {
        id: "delete-file-icon",
        disabled: _unref(operationIsExecuting) && isFileProcessing(_ctx.file.id),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('delete-file', _ctx.file.id)))
      }, [
        _createVNode(DeleteIcon, {
          class: "icon-color",
          width: "1.5rem",
          height: "1.5rem"
        })
      ], 8, _hoisted_10)
    ])
  ]))
}
}

})