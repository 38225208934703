<script>
import { mapActions } from 'vuex';
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc';

export default {
  name: 'OidcCallback',
  props: {
    silentMode: { type: Boolean, required: false },
  },
  methods: {
    ...mapActions(['oidcSignInCallback']),
  },
  mounted() {
    if (this.silentMode) {
      vuexOidcProcessSilentSignInCallback();
      return;
    }
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath);
      })
      .catch((err) => {
        console.error(err);
      });
  },
};
</script>
