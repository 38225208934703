import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { for: "toggle-switch" }
const _hoisted_2 = { class: "switch" }

import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ToggleSwitch',
  props: {
    modelValue: { type: Boolean },
    label: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const isChecked = ref(props.modelValue);

function emitToggle(): void {
  emit("update:modelValue", isChecked.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("label", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isChecked).value = $event)),
        type: "checkbox",
        id: "toggle-switch",
        onChange: emitToggle
      }, null, 544), [
        [_vModelCheckbox, isChecked.value]
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "slider" }, null, -1))
    ])
  ]))
}
}

})