enum MatomoWatermark {
  ADD_FILES = 'Click Add more files',
  CANCEL = 'Click Cancel',
  APPLY = 'Click Apply',
  REMOVE_FILE = 'Click Remove file',
  EDIT_ALL = 'Click Edit all',
  PREVIEW = 'Click Preview file',
  START_OVER = 'Click Start over',
  DOWNLOAD = 'Click Download',
}

export default MatomoWatermark;
