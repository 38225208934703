import OnlyOfficeConfig from "@/interfaces/OnlyOfficeConfig"

export const config: OnlyOfficeConfig = {
  document: {
    fileType: "",
    key: "",
    url: "",
    permissions: {
      download: false,
      edit: false,
      fillForms: true,
      print: false,
      chat: false,
      copy: false,
    },
  },
  token: "",
  editorConfig: {
    user: {
      name: "",
    },
    customization: {
      comments: false,
      compactHeader: true,
      compactToolbar: true,
      toolbarNoTabs: true,
      toolbarHideFileName: true,
      uiTheme: "theme-light",
      plugins: false,
      help: false,
    },
  },
};
