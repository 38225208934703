import FileFromHistory from '@/interfaces/FileFromHistory';
import axios from 'axios';

const historyApi = {
  async getUserCurrentFiles() {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_BASE_URL}/api/files`,
    })
      .then((response) => response.data)
      .catch((err) => {
        console.error(err);
      });
  },
  async getAvailableFormatsForFile(id: string) {
    return axios({
      method: 'OPTIONS',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${id}`,
    })
      .then((response) => response.data.format)
      .catch((err) => {
        console.error(err);
      });
  },
  async downloadFileFromHistory(file: FileFromHistory, format: string) {
    return axios({
      method: 'GET',
      url: `${process.env.VUE_APP_BASE_URL}/api/files/${file.id}?format=${format}`,
      headers: {
        'Content-Type': 'application/json',
      },
      responseEncoding: 'binary',
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const fileName = file.name.replace(/\.[^.]+$/, `.${format.toLowerCase()}`);
        const blob = new Blob([response.data]);
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = `${fileName}`;
        link.href = blobUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      })
      .catch((err) => console.error(err));
  },
};
export default historyApi;
